<template>
  <div class="rentAnalysis wrap">
    <div class="container">
      <div class="tab fl">
        <ul class="fl">
          <li
            v-for="item in tabList"
            :key="item.value"
            :class="{'active' : tabValue === item.value}"
            @click="tabValue = item.value"
          >
            <span>{{item.label}}</span>
          </li>
        </ul>
      </div>
      <components
        :is="componentName[tabValue]"
        :filtroProject='filtroProject'
        :filtroStore='filtroStore'
        :storeOptions='storeOptions'
      >
      </components>
    </div>
  </div>
</template>

<script>
import rentLink from '@/views/storeEarlywarning/rentAnalysis/components/rentLink.vue'
import rentRank from '@/views/storeEarlywarning/rentAnalysis/components/rentRank.vue'
import storeContrast from '@/views/storeEarlywarning/rentAnalysis/components/storeContrast.vue'
import timeContrast from '@/views/storeEarlywarning/rentAnalysis/components/timeContrast.vue'
export default {
  name: 'rentAnalysis',
  components: {
    rentLink,
    rentRank,
    storeContrast,
    timeContrast
  },
  data () {
    return {
      tabValue: 0,
      tabList: [
        {
          label: '租金关联分析',
          value: 0
        },
        {
          label: '租金排名分析',
          value: 1
        },
        {
          label: '店铺租金对比',
          value: 2
        },
        {
          label: '时段租金对比',
          value: 3
        }
      ],
      componentName: ['rentLink', 'rentRank', 'storeContrast', 'timeContrast']
    }
  },
  props: {
    filtroProject: {
      type: Number
    },
    filtroStore: {
      type: String
    },
    storeOptions: {
      type: Array
    }
  },
  mounted () {
    // console.log('filtroStore2222', this.filtroStore)
  },
  watch: {
    filtroProject (newValue, oldValue) {
      console.log('filtroProject from index', this.filtroProject)
    },
    filtroStore (newValue, oldValue) {
      console.log('filtroStore from index', this.filtroStore)
    }
  },
  methods: {
    handleClick (tabValue, event) {
      console.log(tabValue, event)
    }
  }
}
</script>

<style lang="stylus" scoped>
.wrap
  width 100%
  // min-height 850px
.location
  width 100%
  height 60px
  line-height 60px
  color #808191
  font-size 14px
.container
  margin-right 23px
.tab
  float left
  height 60px
  width 100%
  border-bottom 1px solid #373A43
  line-height 60px
  font-size 18px
  color #808191
  display inline-block
  background #272930
  border-radius 2px 2px 0 0
  ul
    float left
    list-style none
    li
      margin 0 45px
      float left
      width 108px
      cursor pointer
      &:hover span:first-child
        color #fff
        padding-bottom 16px
        border-bottom 3px solid #FFA134
      span:first-child
        font-size 18px
        color #A5A5A7
.active>span
  font-weight bold
  color #fff !important
  padding-bottom 16px !important
  border-bottom 3px solid #FFA134
</style>
