<template>
  <div>
    <div class="view1">
      <div class="selectBtn-view">
        <div class="type-item mr-70">
          对比指标：
          <div class="type-item-btn">
            <span
              @click="setIndicator('租金')"
              :class="[indicator === '租金' ? 'active': '']"
            >租金</span>
            <span
              @click="setIndicator('租金坪效')"
              :class="[indicator === '租金坪效' ? 'active': '']"
            >租金坪效</span>
            <span
              @click="setIndicator('租售比')"
              :class="[indicator === '租售比' ? 'active': '']"
            >租售比</span>
          </div>
        </div>
        <div class="type-item mr-70">
          时间范围1：
          <div class="type-item-select">
            <el-date-picker
              popper-class="orange-el-date-picker"
              v-model="startTimeA"
              type="month"
              placeholder="选择日期"
              value-format="yyyy-MM"
              @change="setStartTimeA()"
              :picker-options="pickerOptions_startA"
            >
            </el-date-picker>
            <span>至</span>
            <el-date-picker
              popper-class="orange-el-date-picker"
              v-model="endTimeA"
              type="month"
              placeholder="选择日期"
              value-format="yyyy-MM"
              @change="setEndTimeA()"
              :picker-options="pickerOptions_endA"
            >
            </el-date-picker>
          </div>
          <span class="VS">VS</span>
          时间范围2：
          <div class="type-item-select">
            <el-date-picker
              popper-class="orange-el-date-picker"
              v-model="startTimeB"
              type="month"
              placeholder="选择日期"
              value-format="yyyy-MM"
              @change="setStartTimeB()"
              :picker-options="pickerOptions_startB"
            >
            </el-date-picker>
            <span>至</span>
            <el-date-picker
              popper-class="orange-el-date-picker"
              v-model="endTimeB"
              type="month"
              placeholder="选择日期"
              value-format="yyyy-MM"
              @change="setEndTimeB()"
              :picker-options="pickerOptions_endB"
            >
            </el-date-picker>
          </div>
        </div>
      </div>
      <div class="contrast">
        <div
          class="contrast-chart"
          id="contrast-chart"
        >
          <div class="contrast-checkBox">
            <span class="contrast-title">时段租金对比</span>
            <div class="checkBox fr">
              <el-checkbox
                @change="selectChart(0)"
                v-model="singleMonthComparison"
              >单月对比</el-checkbox>
              <el-checkbox
                @change="selectChart(1)"
                v-model="totalComparison"
              >总计对比</el-checkbox>
            </div>
          </div>
          <!-- 单月对比 -->
          <div
            v-show="singleMonthComparison"
            id="singleCharts"
            class="charts-box"
          ></div>
          <!-- 总计对比 -->
          <div
            v-show="totalComparison"
            id="totalCharts"
            class="charts-box"
          ></div>
        </div>
        <div class="contrast-data">
          <span class="contrast-title">租金名目对比</span>
          <ul class="fr">
            <li class="fl">
              <span></span>
              <!-- {{startTimeA + '~' + endTimeA}} -->
              时间范围1
            </li>
            <li class="fl">
              <span></span>
              <!-- {{startTimeB + '~' + endTimeB}} -->
              时间范围2
            </li>
          </ul>
          <div
            class="contrast-content"
            v-for="(item,index) in contrastList"
            :key="index"
          >
            <div class="contrast-content-title">
              <span>{{item.percent1}}</span>
              <span>{{item.name}}</span>
              <span>{{item.percent2}}</span>
            </div>
            <div class="doubleProgress">
              <div class="progress-bg">
                <div
                  :style="{
                    'width': item.progress1,
                  }"
                  class="progress-l"
                >
                </div>
              </div>
              <div class="progress-bg">
                <div
                  :style="{
                    'width': item.progress2,
                  }"
                  class="progress-r"
                >
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import api from '@/api'
import echarts from 'echarts'
import { dateFormat } from '@/utils/index'
export default {
  name: 'timeContrast',
  data () {
    return {
      indicator: '租金',
      contrastList: [{ // 进度条数据
        name: '固定租金',
        percent1: '-%', // 本店铺(显示数值用)
        progress1: '0%', // 本店铺(设置进度条宽度用)
        percent2: '-%', // 对比店铺(显示数值用)
        progress2: '0%' // 对比店铺(设置进度条宽度用)
      }, {
        name: '提成租金',
        percent1: '-%',
        progress1: '0%',
        percent2: '-%',
        progress2: '0%'
      }],
      singleCharts: {},
      totalCharts: {},
      labels: [], // 下标(日期)
      compareLabels: [], // 对比下标(日期)
      rents: '', // 销售金额(单月对比)
      compareRents: '', // 对比销售金额(单月对比)
      rentAffect: '', // 销售笔数(单月对比)
      compareRentAffect: '', // 对比销售笔数(单月对比)
      salesRentRatio: '', // 租售比(单月对比)
      compareSalesRentRatio: '', // 对比租售比(单月对比)
      sumLabels: '', // 横坐标(总计对比图)
      sumRents: '', // 租金总额(总计对比图)
      sumRentAffect: '', // 租金坪效总额(总计对比图)
      sumSalesRentRatio: '', // 租售比总额(总计对比图)
      startTimeA: '',
      endTimeA: '',
      startTimeB: '',
      endTimeB: '',
      pickerOptions_startA: {
        disabledDate: (time) => {
          if (this.endTimeA) {
            return time.getTime() > Date.now() || time.getTime() > this.endTimeA
          } else {
            return time.getTime() > Date.now()
          }
        }
      },
      pickerOptions_endA: {
        disabledDate: (time) => {
          return time.getTime() < this.startTimeA || time.getTime() > Date.now()
        }
      },
      pickerOptions_startB: {
        disabledDate: (time2) => {
          if (this.endTimeB) {
            return time2.getTime() > Date.now() || time2.getTime() > this.endTimeB
          } else {
            return time2.getTime() > Date.now()
          }
        }
      },
      pickerOptions_endB: {
        disabledDate: (time2) => {
          return time2.getTime() < this.startTimeB || time2.getTime() > Date.now()
        }
      },
      isShowSingleChart: true, // 单月对比图
      isShowTotalChart: true, // 总计对比图
      currentPage1: 1,
      currentPage2: 1,
      singleMonthComparison: true,
      totalComparison: false
    }
  },
  props: {
    filtroProject: {
      type: Number
    },
    filtroStore: {
      type: String
    }
  },
  watch: {
    filtroStore (newValue, oldValue) {
      this.getData()
    }
  },
  mounted () {
    this.setCurrentTime()
  },
  methods: {
    // 生成时间范围数组
    getMonthBetween (start, end) {
      const result = []
      const s = start.split('-')
      const e = end.split('-')
      const min = new Date()
      const max = new Date()
      min.setFullYear(s[0], s[1])
      max.setFullYear(e[0], e[1])
      let curr = min
      while (curr <= max) {
        const month = curr.getMonth()
        let tempmonth = month + ''
        if (tempmonth.length === 1 && tempmonth !== '0') {
          tempmonth = '0' + tempmonth
        }
        let str = curr.getFullYear() + '-' + (tempmonth)
        const s = curr.getFullYear() + '-0'
        if (str === s) {
          str = curr.getFullYear() - 1 + '-12'
        }
        result.push(str)
        curr.setMonth(month + 1)
        curr = new Date(curr)
      }
      return result
    },
    // 折线图缺省数据
    setDefaultLine () {
      this.labels = []
      this.rents = []
      this.rentAffect = []
      this.salesRentRatio = []
      this.compareLabels = []
      this.compareRents = []
      this.compareRentAffect = []
      this.compareSalesRentRatio = []
      const timeA = this.getMonthBetween(this.startTimeA, this.endTimeA)
      for (let i = 0; i < timeA.length; i++) {
        this.labels.push(timeA[i])
        this.rents.push('')
        this.rentAffect.push('')
        this.salesRentRatio.push('')
      }
      const timeB = this.getMonthBetween(this.startTimeB, this.endTimeB)
      for (let i = 0; i < timeB.length; i++) {
        this.compareLabels.push(timeB[i])
        this.compareRents.push('')
        this.compareRentAffect.push('')
        this.compareSalesRentRatio.push('')
      }
    },
    // 柱状图缺省数据
    setDefaultHistogram () {
      const timeLimit1 = this.startTimeA.replace(/-/g, '/') + '~' + this.endTimeA.replace(/-/g, '/')
      const timeLimit2 = this.startTimeB.replace(/-/g, '/') + '~' + this.endTimeB.replace(/-/g, '/')
      this.sumLabels = [timeLimit1, timeLimit2]
      this.sumRents = ['', '']
      this.sumRentAffect = ['', '']
      this.sumSalesRentRatio = ['', '']
    },
    selectChart (val) {
      if (val === 0) {
        this.singleMonthComparison = true
        this.totalComparison = false
      } else if (val === 1) {
        this.singleMonthComparison = false
        this.totalComparison = true
      }
      this.changeChart()
    },
    // 设置时间初始值
    setCurrentTime () {
      // 设置时间范围1默认值
      const endA = new Date()
      const startA = new Date()
      endA.setTime(endA.getTime() - 3600 * 1000 * 24 * 30)
      this.endTimeA = dateFormat('yyyy-MM', endA)
      startA.setTime(startA.getTime() - 3600 * 1000 * 24 * 60)
      this.startTimeA = dateFormat('yyyy-MM', startA)
      // 设置时间范围2默认值
      const endB = new Date()
      const startB = new Date()
      endB.setTime(endB.getTime() - 3600 * 1000 * 24 * 90)
      this.endTimeB = dateFormat('yyyy-MM', endB)
      startB.setTime(startB.getTime() - 3600 * 1000 * 24 * 120)
      this.startTimeB = dateFormat('yyyy-MM', startB)
      this.getData()
    },
    // 获取数据
    getData () {
      this.labels = []
      this.compareLabels = []
      this.rents = ''
      this.compareRents = ''
      this.rentAffect = ''
      this.compareRentAffect = ''
      this.salesRentRatio = ''
      this.compareSalesRentRatio = ''
      this.sumLabels = ''
      this.sumRents = ''
      this.sumRentAffect = ''
      this.sumSalesRentRatio = ''
      const params = {
        projectId: this.filtroProject,
        storeCode: this.filtroStore,
        startMonth: this.startTimeA,
        endMonth: this.endTimeA,
        compareStartMonth: this.startTimeB,
        compareEndMonth: this.endTimeB
      }
      this.axios.get(api.getStoreDateRentCompareByCondition, { params: params }).then(res => {
        if (res.data.code === 0) {
          this.isShowSingleChart = true
          this.isShowTotalChart = true
          const resData = res.data.data
          // 单月对比图表数据
          this.labels = resData.labels
          this.compareLabels = resData.compareLabels
          this.rents = resData.rents.map(item => {
            return (item / 10000).toFixed(2) // 单位转换，元转万元
          })
          this.compareRents = resData.compareRents.map(item => {
            return (item / 10000).toFixed(2) // 单位转换，元转万元
          })
          this.rentAffect = resData.rentAffect
          this.compareRentAffect = resData.compareRentAffect
          this.salesRentRatio = resData.salesRentRatio
          this.compareSalesRentRatio = resData.compareSalesRentRatio
          // 总计对比图表数据
          this.sumLabels = resData.sumLabels
          this.sumRents = resData.sumRents.map(item => {
            return (item / 10000).toFixed(2) // 单位转换，元转万元
          })
          this.sumRentAffect = resData.sumRentAffect
          this.sumSalesRentRatio = resData.sumSalesRentRatio
          // 支付渠道对比
          if (resData.rentItemVo) {
            this.contrastList[0].percent1 = resData.rentItemVo.fixedRentRatio
            this.contrastList[0].progress1 = resData.rentItemVo.fixedRentRatio
            this.contrastList[1].percent1 = resData.rentItemVo.takeRentRatio
            this.contrastList[1].progress1 = resData.rentItemVo.takeRentRatio
          } else {
            this.contrastList[0].percent1 = '-%'
            this.contrastList[0].progress1 = '0%'
            this.contrastList[1].percent1 = '-%'
            this.contrastList[1].progress1 = '0%'
          }
          if (resData.compareRentItemVo) {
            this.contrastList[0].percent2 = resData.compareRentItemVo.fixedRentRatio
            this.contrastList[0].progress2 = resData.compareRentItemVo.fixedRentRatio
            this.contrastList[1].percent2 = resData.compareRentItemVo.takeRentRatio
            this.contrastList[1].progress2 = resData.compareRentItemVo.takeRentRatio
          } else {
            this.contrastList[0].percent2 = '-%'
            this.contrastList[0].progress2 = '0%'
            this.contrastList[1].percent2 = '-%'
            this.contrastList[1].progress2 = '0%'
          }
          this.changeChart()
        } else {
          this.isShowSingleChart = false
          this.isShowTotalChart = false
          this.setDefaultLine()
          this.setDefaultHistogram()
          this.changeChart() // 展示图表
        }
      })
    },
    setIndicator (val) {
      this.indicator = val
      this.changeChart()
    },
    showSingleChartData () {
      switch (this.indicator) {
        case '租金':
          // 图表数据处理
          if ((this.rents && this.rents.length > 0) || (this.compareRents && this.compareRents.length > 0)) {
            this.isShowSingleChart = true
            const obj = {
              indicator: '租金', // y轴名
              unit: '万元', // y轴名单位
              time1: '', // 时间范围1
              time2: '', // 时间范围2
              label1: [], // 横坐标
              label2: [], // 横坐标
              value1: [], // 第一条线的值
              value2: [], // 第二条线的值
              xAxisIndexValue1: 0, // 设置主轴
              xAxisIndexValue2: 1 // 设置主轴
            }
            // 调整X轴长度和顺序
            const coordinate1 = this.rents || [] // 坐标值
            const coordinate2 = this.compareRents || [] // 坐标值
            const labelsArr = JSON.parse(JSON.stringify(this.labels)) // X轴名
            const compareLabelsArr = JSON.parse(JSON.stringify(this.compareLabels)) // X轴名
            const difference = labelsArr.length - compareLabelsArr.length // 根据差值，补空，使两个X轴坐标数组长度一样
            if (difference > 0) {
              for (let i = 0; i < difference; i++) {
                compareLabelsArr.push('')
                coordinate2.push('')
              }
            } else if (difference < 0) {
              for (let i = 0; i < Math.abs(difference); i++) {
                labelsArr.push('')
                coordinate1.push('')
              }
            }
            obj.value1 = coordinate1
            obj.value2 = coordinate2
            obj.time1 = '时间范围1'
            obj.time2 = '时间范围2'
            // 判断主X轴,调整顺序
            if (difference >= 0) {
              obj.xAxisIndexValue1 = 0
              obj.xAxisIndexValue2 = 1
              obj.label1 = labelsArr
              obj.label2 = compareLabelsArr
            } else if (difference < 0) {
              obj.xAxisIndexValue1 = 1
              obj.xAxisIndexValue2 = 0
              obj.label1 = compareLabelsArr
              obj.label2 = labelsArr
            }
            this.getSingleChart(obj)
          } else {
            this.isShowSingleChart = false
            // this.setDefaultLine()
          }
          break
        case '租金坪效':
          // 图表数据处理
          if ((this.rentAffect && this.rentAffect.length > 0) || (this.compareRentAffect && this.compareRentAffect.length > 0)) {
            this.isShowSingleChart = true
            const obj = {
              indicator: '租金坪效', // y轴名
              unit: '元/月/㎡', // y轴名单位
              time1: '', // 时间范围1
              time2: '', // 时间范围2
              label1: [], // 横坐标
              label2: [], // 横坐标
              value1: [], // 第一条线的值
              value2: [], // 第二条线的值
              xAxisIndexValue1: 0, // 设置主轴
              xAxisIndexValue2: 1 // 设置主轴
            }
            // 调整X轴长度和顺序
            const coordinate1 = this.rentAffect || [] // 坐标值
            const coordinate2 = this.compareRentAffect || [] // 坐标值
            const labelsArr = JSON.parse(JSON.stringify(this.labels)) // X轴名
            const compareLabelsArr = JSON.parse(JSON.stringify(this.compareLabels)) // X轴名
            const difference = labelsArr.length - compareLabelsArr.length // 根据差值，补空，使两个X轴坐标数组长度一样
            if (difference > 0) {
              for (let i = 0; i < difference; i++) {
                compareLabelsArr.push('')
                coordinate2.push('')
              }
            } else if (difference < 0) {
              for (let i = 0; i < Math.abs(difference); i++) {
                labelsArr.push('')
                coordinate1.push('')
              }
            }
            obj.value1 = coordinate1
            obj.value2 = coordinate2
            obj.time1 = '时间范围1'
            obj.time2 = '时间范围2'
            // 判断主X轴,调整顺序
            if (difference >= 0) {
              obj.xAxisIndexValue1 = 0
              obj.xAxisIndexValue2 = 1
              obj.label1 = labelsArr
              obj.label2 = compareLabelsArr
            } else {
              obj.xAxisIndexValue1 = 1
              obj.xAxisIndexValue2 = 0
              obj.label1 = compareLabelsArr
              obj.label2 = labelsArr
            }
            this.getSingleChart(obj)
          } else {
            this.isShowSingleChart = false
            // this.setDefaultLine()
          }
          break
        case '租售比':
          // 图表数据处理
          if ((this.salesRentRatio && this.salesRentRatio.length > 0) || (this.compareSalesRentRatio && this.compareSalesRentRatio.length > 0)) {
            this.isShowSingleChart = true
            const obj = {
              indicator: '租售比', // y轴名
              unit: '%', // y轴名单位
              time1: '', // 时间范围1
              time2: '', // 时间范围2
              label1: [], // 横坐标
              label2: [], // 横坐标
              value1: [], // 第一条线的值
              value2: [], // 第二条线的值
              xAxisIndexValue1: 0, // 设置主轴
              xAxisIndexValue2: 1 // 设置主轴
            }
            // 调整X轴长度和顺序
            const coordinate1 = this.salesRentRatio || [] // 坐标值
            const coordinate2 = this.compareSalesRentRatio || [] // 坐标值
            const labelsArr = JSON.parse(JSON.stringify(this.labels)) // X轴名
            const compareLabelsArr = JSON.parse(JSON.stringify(this.compareLabels)) // X轴名
            const difference = labelsArr.length - compareLabelsArr.length // 根据差值，补空，使两个X轴坐标数组长度一样
            if (difference > 0) {
              for (let i = 0; i < difference; i++) {
                compareLabelsArr.push('')
                coordinate2.push('')
              }
            } else if (difference < 0) {
              for (let i = 0; i < Math.abs(difference); i++) {
                labelsArr.push('')
                coordinate1.push('')
              }
            }
            obj.value1 = coordinate1
            obj.value2 = coordinate2
            obj.time1 = '时间范围1'
            obj.time2 = '时间范围2'
            // 判断主X轴,调整顺序
            if (difference >= 0) {
              obj.xAxisIndexValue1 = 0
              obj.xAxisIndexValue2 = 1
              obj.label1 = labelsArr
              obj.label2 = compareLabelsArr
            } else {
              obj.xAxisIndexValue1 = 1
              obj.xAxisIndexValue2 = 0
              obj.label1 = compareLabelsArr
              obj.label2 = labelsArr
            }
            this.getSingleChart(obj)
          } else {
            this.isShowSingleChart = false
            // this.setDefaultLine()
          }
          break
      }
    },
    showTotalChartData () {
      switch (this.indicator) {
        case '租金':
          // 图表数据处理
          if (this.sumRents && this.sumRents.length > 0) {
            this.isShowTotalChart = true
            const obj = {
              indicator: '租金', // y轴名
              unit: '万元', // y轴名单位
              label: this.sumLabels, // 横坐标
              value: this.sumRents
            }
            this.getTotalChart(obj)
          } else {
            this.isShowTotalChart = false
            // this.setDefaultHistogram()
          }
          break
        case '租金坪效':
          // 图表数据处理
          if (this.sumRentAffect && this.sumRentAffect.length > 0) {
            this.isShowTotalChart = true
            const obj = {
              indicator: '租金坪效', // y轴名
              unit: '元/月/㎡', // y轴名单位
              label: this.sumLabels, // 横坐标
              value: this.sumRentAffect
            }
            this.getTotalChart(obj)
          } else {
            this.isShowTotalChart = false
            // this.setDefaultHistogram()
          }
          break
        case '租售比':
          // 图表数据处理
          if (this.sumSalesRentRatio && this.sumSalesRentRatio.length > 0) {
            this.isShowTotalChart = true
            const obj = {
              indicator: '租售比', // y轴名
              unit: '%', // y轴名单位
              label: this.sumLabels, // 横坐标
              value: this.sumSalesRentRatio
            }
            this.getTotalChart(obj)
          } else {
            this.isShowTotalChart = false
            // this.setDefaultHistogram()
          }
          break
      }
    },
    // 选择时间
    setStartTimeA () {
      const start = new Date(this.startTimeA).getTime()
      const end = new Date(this.endTimeA).getTime()
      const difference = (end - start) / (1000 * 60 * 60 * 24)
      if (this.endTimeA) {
        if (start > end) {
          this.$message({
            type: 'warning',
            message: '开始时间不能大于结束时间'
          })
          return false
        }
        if (difference > 365 * 3) {
          this.$message({
            type: 'warning',
            message: '时间范围不能超过3年'
          })
          return false
        }
        this.getData()
      }
    },
    setEndTimeA () {
      const start = new Date(this.startTimeA).getTime()
      const end = new Date(this.endTimeA).getTime()
      const difference = (end - start) / (1000 * 60 * 60 * 24)
      if (this.startTimeA) {
        if (start > end) {
          this.$message({
            type: 'warning',
            message: '开始时间不能大于结束时间'
          })
          return false
        }
        if (difference > 365 * 3) {
          this.$message({
            type: 'warning',
            message: '时间范围不能超过3年'
          })
          return false
        }
        this.getData()
      }
    },
    setStartTimeB () {
      const start = new Date(this.startTimeB).getTime()
      const end = new Date(this.endTimeB).getTime()
      const difference = (end - start) / (1000 * 60 * 60 * 24)
      if (this.endTimeB) {
        if (start > end) {
          this.$message({
            type: 'warning',
            message: '开始时间不能大于结束时间'
          })
          return false
        }
        if (difference > 365 * 3) {
          this.$message({
            type: 'warning',
            message: '时间范围不能超过3年'
          })
          return false
        }
        this.getData()
      }
    },
    setEndTimeB () {
      const start = new Date(this.startTimeB).getTime()
      const end = new Date(this.endTimeB).getTime()
      const difference = (end - start) / (1000 * 60 * 60 * 24)
      if (this.startTimeB) {
        if (start > end) {
          this.$message({
            type: 'warning',
            message: '开始时间不能大于结束时间'
          })
          return false
        }
        if (difference > 365 * 3) {
          this.$message({
            type: 'warning',
            message: '时间范围不能超过3年'
          })
          return false
        }
        this.getData()
      }
    },
    // 切换图表
    changeChart () {
      if (this.singleMonthComparison) { // 明细对比图
        this.showSingleChartData()
      } else { // 总计对比
        this.showTotalChartData()
      }
    },
    // 画图表-单月对比
    getSingleChart (obj) {
      // console.log('getSingleChart.obj', obj)
      const maxValue = this.isShowSingleChart ? null : 1750
      const mychart = document.getElementById('singleCharts')
      const divWidth = document.getElementById('contrast-chart')
      mychart.style.width = divWidth.offsetWidth + 'px'
      this.singleCharts = echarts.init(document.getElementById('singleCharts'))
      this.singleCharts.setOption({
        color: ['#3F8CFF', '#A0D7E7'],
        legend: {
          itemHeight: 10,
          itemWidth: 10,
          itemGap: 30,
          top: 2,
          right: 250,
          icon: 'circle',
          textStyle: {
            color: 'rgba(255, 255, 255, .45)',
            fontFamily: 'PingFang SC',
            fontSize: 12
          },
          data: [obj.time1, obj.time2]
        },
        tooltip: {
          show: true,
          trigger: 'axis',
          backgroundColor: 'rgba(0,0,0,0.85)',
          textStyle: {
            fontSize: 12
          },
          formatter: (params) => {
            if (params.length > 1 || params[0].axisValue !== '') {
              let html = '<div style="padding:4px; font-family: PingFang SC;">'
              for (let i = 0; i < params.length; i++) {
                if (params[i].name) {
                  html += '<span style="color:#b2b3bd">' + params[i].marker + ' ' + params[i].seriesName + ' ' + params[i].name + '： ' + '</span>' + ((params[i].data || params[i].data === 0) ? params[i].data : '-') + obj.unit + '<br>'
                }
              }
              html += '</div>'
              return html
            }
          }
        },
        grid: {
          top: '15%',
          left: '5%',
          right: '5%',
          bottom: '1%',
          containLabel: true
        },
        xAxis: [{
          data: obj.label1,
          boundaryGap: false,
          type: 'category',
          offset: 6,
          axisTick: {
            show: true
          },
          axisLabel: {
            color: '#808191'
          },
          axisLine: {
            lineStyle: {
              color: '#373A43'
            }
          },
          axisPointer: {
            lineStyle: {
              color: 'rgba(255,255,255,.1)'
            }
          }
        }, {
          show: false,
          data: obj.label2,
          boundaryGap: false,
          type: 'category',
          axisTick: {
            show: true
          },
          axisLabel: {
            color: 'rgba(255, 255, 255, .45)'
          },
          axisPointer: {
            lineStyle: {
              color: 'rgba(255,255,255,.1)'
            }
          }
        }],
        yAxis: {
          name: obj.indicator + '(' + obj.unit + ')',
          nameTextStyle: {
            color: '#808191',
            fontFamily: 'PingFang SC',
            fontSize: 12
          },
          min: 0,
          max: maxValue,
          type: 'value',
          axisTick: {
            show: false
          },
          splitLine: {
            lineStyle: {
              color: '#373A43',
              type: 'dashed'
            }
          },
          axisLine: {
            lineStyle: {
              color: '#373A43'
            }
          },
          axisLabel: {
            color: '#808191',
            formatter: '{value}'
          }
        },
        series: [
          {
            name: obj.time1,
            type: 'line',
            smooth: true,
            emphasis: {
              focus: 'series'
            },
            lineStyle: {
              color: '#3F8CFF'
            },
            xAxisIndex: obj.xAxisIndexValue1, // 设置X轴
            areaStyle: {
              opacity: 1,
              color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [{
                offset: 0,
                color: 'rgba(62, 140, 255, .3)'
              }, {
                offset: 1,
                color: 'rgba(62, 140, 255, 0)'
              }])
            },
            data: obj.value1
          },
          {
            name: obj.time2,
            type: 'line',
            smooth: true,
            emphasis: {
              focus: 'series'
            },
            lineStyle: {
              color: 'rgba(160, 215, 231, 1)'
            },
            xAxisIndex: obj.xAxisIndexValue2, // 设置X轴
            areaStyle: {
              opacity: 1,
              color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [{
                offset: 0,
                color: 'rgba(160, 215, 231, .3)'
              }, {
                offset: 1,
                color: 'rgba(160, 215, 231, 0)'
              }])
            },
            data: obj.value2
          }
        ]
      })
      this.$nextTick(() => {
        window.addEventListener('resize', () => {
          mychart.style.width = divWidth.offsetWidth + 'px'
          this.singleCharts.resize()
        })
      })
    },
    // 画图表-总计对比
    getTotalChart (obj) {
      // console.log('getTotalChart.obj', obj)
      const maxValue = this.isShowTotalChart ? null : 1750
      const mychart = document.getElementById('totalCharts')
      const divWidth = document.getElementById('contrast-chart')
      mychart.style.width = divWidth.offsetWidth + 'px'
      this.totalCharts = echarts.init(document.getElementById('totalCharts'))
      this.totalCharts.setOption({
        color: ['#3F8CFF'],
        tooltip: {
          trigger: 'axis',
          backgroundColor: 'rgba(0,0,0,0.85)',
          textStyle: {
            fontSize: 12
          },
          formatter: function (params) {
            return '<span style="color: #808191;">' + params[0].axisValueLabel + ': </span>' + ((params[0].data || params[0].data === 0) ? params[0].data : '-') + obj.unit
          },
          axisPointer: {
            type: 'shadow'
          }
        },
        grid: {
          top: '15%',
          left: '5%',
          right: '5%',
          bottom: '1%',
          containLabel: true
        },
        xAxis: {
          type: 'category',
          offset: 6,
          axisTick: {
            show: true
          },
          axisLabel: {
            color: '#808191'
          },
          axisLine: {
            lineStyle: {
              color: '#373A43'
            }
          },
          data: obj.label
        },
        yAxis: {
          name: obj.indicator + '(' + obj.unit + ')',
          type: 'value',
          nameTextStyle: {
            color: '#808191',
            fontFamily: 'PingFang SC',
            fontSize: 12
          },
          splitLine: {
            lineStyle: {
              color: '#373A43',
              type: 'dashed'
            }
          },
          min: 0,
          max: maxValue,
          axisLine: {
            lineStyle: {
              color: '#373A43'
            }
          },
          axisLabel: {
            color: '#808191',
            formatter: '{value}'
          }
        },
        series: [{
          barWidth: 60,
          data: obj.value,
          type: 'bar',
          label: { // 图形上的文本标签
            show: true,
            position: 'top', // 相对位置
            color: 'rgba(255, 255, 255, .45)'
          }
        }]
      })
      this.$nextTick(() => {
        window.addEventListener('resize', () => {
          this.totalCharts.resize()
        })
      })
    }
  }
}
</script>

<style lang="stylus" scoped>
.view1
  padding-top 60px
  background #272930
  opacity 1
  border-radius 0 0 2px 2px
  .selectBtn-view
    margin-top 17px
    padding-left 24px
    line-height 33px
    display flex
    flex-flow wrap
    .type-item
      margin-top 15px
      display flex
      font-size 14px
      font-family PingFang SC
      color #B2B3BD
      .type-item-btn
        padding 0 3px
        height 33px
        border 1px solid #373A43
        opacity 1
        border-radius 2px
        span
          display inline-block
          width 77px
          height 27px
          line-height 27px
          font-size 14px
          font-family PingFang SC
          font-weight 500
          color rgba(255, 255, 255, 0.5)
          text-align center
          margin-right -1px
          cursor pointer
          &.active
            background #FFA134
            color #fff
            border-color #FFA134
        span:first-child
          border-radius 2px 0px 0px 2px
        span:last-child
          border-radius 0px 2px 2px 0px
      .type-item-select
        background-color #373A43
        span
          color #B2B3BD
        & >>> .el-select
          width 154px
        & >>> .el-date-editor
          width 100px
          // width 120px
          input
            text-align center
    .VS
      width 42px
      text-align center
      font-weight 800
      color #808191
  .contrast-checkBox
    width 100%
    position absolute
  .checkBox
    margin-right 24px
    & label >>> .el-checkbox__inner
      border 1px #808191 solid !important
    & label >>> .el-checkbox__input.is-checked .el-checkbox__inner
      background-color #ffa134 !important
      border-color #ffa134 !important
    & label >>> .is-checked + span
      color #fff !important
    & label >>> .el-checkbox__input.is-checked
      border-color #ffa134 !important
  .contrast
    display flex
    margin-top 63px
    height 590px
    .contrast-data
      width 40%
      ul
        font-size 12px
        font-family PingFang SC
        font-weight 400
        color #808191
        li
          margin-right 24px
          span
            display inline-block
            width 8px
            height 8px
            border-radius 50%
            background #3F8CFF
          &:nth-child(2) span
            background #A0D7E7
      .contrast-content
        margin 40px 24px auto 24px
        .contrast-content-title
          justify-content space-between
          display flex
          margin-bottom 15px
          span
            font-size 16px
            font-family PingFang SC
            color #FFFFFF
          span:nth-child(2)
            font-size 14px
            font-weight 400
            color #B2B3BD
    .contrast-chart
      position relative
      border-right 1px solid #373A43
      width 60%
      .charts-box
        width 100%
        height 550px
  .contrast-title
    padding-left 24px
    font-size 16px
    font-family PingFang SC
    font-weight bold
    color #FFFFFF
.clear
  clear both
.mr-70
  margin-right 70px
// elm-ui样式
// 1.下拉框
/deep/ .el-input__inner
  height 33px
  background-color #373A43
  color #808191
  border none
  border-radius 2px
  padding 0 18px
// 2.日期组件样式
// （1）日期icon
/deep/ .el-input__prefix
  display none
/deep/ .el-date-editor .el-input__suffix
  display none
// （2）输入框禁止点击时
/deep/ .is-disabled .el-input__inner
  color #808191 !important
  background-color #373a43 !important
</style>
