<template>
  <div>
    <div class="view1">
      <div class="selectBtn-view">
        <div class="type-item mr-70">
          对比指标：
          <div class="type-item-btn">
            <span
              v-for="(item,index) in indicatorList"
              :key="index"
              @click="setIndicator(index)"
              :class="[indicatorValue === index ? 'active': '']"
            >{{item}}</span>
          </div>
        </div>
        <div class="type-item mr-70">
          对比店铺：
          <div class="type-item-select no-select">
            <el-select
              popper-class="w-block-select-down disabled-select-panel"
              v-model="currentStore.storeName"
              size="small"
              :disabled="true"
            >
              <el-option
                v-for="(item, index) in storeOptions"
                :key="index"
                :label="item.storeName"
                :value="index"
              >
              </el-option>
            </el-select>
          </div>
          <span class="VS">VS</span>
          <div class="type-item-select">
            <el-select
              popper-class="w-block-select-down yellow-select-panel"
              filterable
              v-model="compareStore.storeName"
              size="small"
              placeholder="请选择"
              @change="selectCompareStore"
            >
              <el-option
                v-for="(item, index) in storeList"
                :key="index"
                :label="item.storeName"
                :value="item.storeName"
              >
              </el-option>
            </el-select>
          </div>
        </div>
        <div class="type-item small">
          时间范围：
          <div class="type-item-select timeLimit">
            <el-select
              popper-class="w-block-select-down yellow-select-panel"
              v-model="timeType"
              size="small"
              placeholder="请选择"
              @change="selectTimeRange"
            >
              <el-option
                v-for="item in timeOptions"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              >
              </el-option>
            </el-select>
            <span class="segmentation">|</span>
            <el-date-picker
              popper-class="orange-el-date-picker"
              :disabled="noEdit"
              v-model="startTime"
              type="month"
              placeholder="选择日期"
              value-format="yyyy-MM"
              :picker-options="pickerOptions_start"
              @change="setStartTime()"
            >
            </el-date-picker>
            <span :class="noEdit?'isDisabled':''">~</span>
            <el-date-picker
              popper-class="orange-el-date-picker"
              :disabled="noEdit"
              v-model="endTime"
              type="month"
              placeholder="选择日期"
              value-format="yyyy-MM"
              :picker-options="pickerOptions_end"
              @change="setEndTime()"
            >
            </el-date-picker>
          </div>
        </div>
      </div>
      <div class="contrast">
        <div class="contrast-chart">
          <div class="contrast-checkBox">
            <span class="contrast-title">店铺租金对比</span>
          </div>
          <div
            id="charts"
            class="charts-box"
          ></div>
        </div>
        <div class="contrast-data">
          <span class="contrast-title">租金名目对比</span>
          <ul class="fr">
            <li class="fl">
              <span></span>
              {{currentStore.storeName?currentStore.storeName:'-'}}
            </li>
            <li class="fl">
              <span></span>
              {{compareStore.storeName?compareStore.storeName:'-'}}
            </li>
          </ul>
          <div
            class="contrast-content"
            v-for="(item,index) in contrastList"
            :key="index"
          >
            <div class="contrast-content-title">
              <span>{{item.percent1}}</span>
              <span>{{item.name}}</span>
              <span>{{item.percent2}}</span>
            </div>
            <div class="doubleProgress">
              <div class="progress-bg">
                <div
                  :style="{
                    'width': item.progress1,
                  }"
                  class="progress-l"
                >
                </div>
              </div>
              <div class="progress-bg">
                <div
                  :style="{
                    'width': item.progress2,
                  }"
                  class="progress-r"
                >
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import api from '@/api'
import echarts from 'echarts'
import { dateFormat } from '@/utils/index'
export default {
  name: 'storeContrast',
  data () {
    return {
      indicatorList: ['租金', '租金坪效', '租售比'],
      indicatorValue: 0,
      charts: {},
      storeList: '', // 除了本店的店铺列表
      currentChart: '', // 本店图表数据
      compareChart: '', // 对比店图表数据
      currentData: [], // 本店支付渠道数据
      compareData: [], // 对比店铺支付渠道数据
      contrastList: [{ // 进度条数据
        name: '固定租金',
        percent1: '-%', // 本店铺(显示数值用)
        progress1: '0%', // 本店铺(设置进度条宽度用)
        percent2: '-%', // 对比店铺(显示数值用)
        progress2: '0%' // 对比店铺(设置进度条宽度用)
      }, {
        name: '提成租金',
        percent1: '-%',
        progress1: '0%',
        percent2: '-%',
        progress2: '0%'
      }],
      currentStore: { // 当前店铺
        storeCode: '',
        storeName: ''
      },
      compareStore: { // 对比店铺
        storeCode: '',
        storeName: ''
      },
      timeType: '近一年',
      timeOptions: [{
        value: '近一年',
        label: '近一年'
      }, {
        value: '本年度',
        label: '本年度'
      }, {
        value: '自定义',
        label: '自定义'
      }],
      startTime: '',
      endTime: '',
      pickerOptions_start: {
        disabledDate: (time) => {
          const t = new Date().getDate()
          if (this.endTime) {
            return time.getTime() >= (Date.now() - 8.64e7 * t) || time.getTime() > this.endTime
          } else {
            return time.getTime() >= (Date.now() - 8.64e7 * t)
          }
        }
      },
      pickerOptions_end: {
        disabledDate: (time) => {
          const t = new Date().getDate()
          return time.getTime() <= this.startTime || time.getTime() >= (Date.now() - 8.64e7 * t)
        }
      },
      noEdit: false,
      isShowChart: true,
      startTimeBak: '', // 临时保存开始时间，回显用
      endTimeBak: '' // 临时保存开始时间，回显用
    }
  },
  props: {
    filtroProject: {
      type: Number
    },
    filtroStore: {
      type: String
    },
    storeOptions: {
      type: Array
    }
  },
  watch: {
    filtroStore (newValue, oldValue) {
      this.setCurrentData()
    }
  },
  mounted () {
    this.setCurrentTime()
    this.setDefaultChart()
    this.setCurrentData()
  },
  methods: {
    setDefaultChart () {
      this.currentChart = []
      const Arr = this.getMonthBetween(this.startTime, this.endTime)
      for (let i = 0; i < Arr.length; i++) {
        const obj = {
          measureMonth: Arr[i],
          rentMoney: '',
          rentEffect: '',
          salesRentRatio: ''
        }
        this.currentChart.push(obj)
      }
    },
    // 生成时间范围数组
    getMonthBetween (start, end) {
      const result = []
      const s = start.split('-')
      const e = end.split('-')
      const min = new Date()
      const max = new Date()
      min.setFullYear(s[0], s[1])
      max.setFullYear(e[0], e[1])
      let curr = min
      while (curr <= max) {
        const month = curr.getMonth()
        let tempmonth = month + ''
        if (tempmonth.length === 1 && tempmonth !== '0') {
          tempmonth = '0' + tempmonth
        }
        let str = curr.getFullYear() + '-' + (tempmonth)
        const s = curr.getFullYear() + '-0'
        if (str === s) {
          str = curr.getFullYear() - 1 + '-12'
        }
        result.push(str)
        curr.setMonth(month + 1)
        curr = new Date(curr)
      }
      return result
    },
    // 设置初始值
    setCurrentData () {
      // 除了本店铺的其他店铺
      this.storeList = this.storeOptions.filter(item => {
        return item.storeCode !== this.filtroStore
      })
      // 当前店铺
      this.currentStore.storeCode = this.filtroStore
      const Temp = this.storeOptions.filter(item => {
        return item.storeCode === this.filtroStore
      })
      this.currentStore.storeName = Temp[0].storeName
      // 获取默认加载店铺
      const params = {
        projectId: this.filtroProject,
        storeCode: this.filtroStore,
        type: 1
      }
      this.axios.get(api.getBaseInfoDefaultCompareStore, { params: params }).then(res => {
        if (res.data.code === 0) {
          if (res.data.data.storeCode) {
            this.compareStore.storeCode = res.data.data.storeCode
            const Arr = this.storeOptions.filter(item => {
              return item.storeCode === res.data.data.storeCode
            })
            this.compareStore.storeName = Arr[0].storeName
          }
          this.getData()
        }
      })
    },
    // 设置时间初始值
    setCurrentTime () {
      // 设置默认时间
      const end = new Date()
      const start = new Date()
      end.setTime(end.getTime() - 3600 * 1000 * 24 * 30)
      this.endTime = dateFormat('yyyy-MM', end)
      start.setTime(start.getTime() - 3600 * 1000 * 24 * 365)
      this.startTime = dateFormat('yyyy-MM', start)
      this.noEdit = true
      this.startTimeBak = dateFormat('yyyy-MM', start)
      this.endTimeBak = dateFormat('yyyy-MM', end)
    },
    // 获取数据
    getData () {
      // 重置数据
      this.currentChart = ''
      this.compareChart = ''
      // 1.获取本店铺数据
      const params = {
        projectId: this.filtroProject,
        storeCode: this.filtroStore,
        rentStartMonth: this.startTime,
        rentEndMonth: this.endTime,
        toCompareStoreCode: ''
      }
      this.axios.get(api.getStoreRentCompareByCondition, { params: params }).then(res => {
        if (res.data.code === 0) {
          this.isShowChart = true
          const resData = res.data.data
          // 本店支付渠道对比
          this.currentData = []
          this.currentData.push(resData.rentItemCompareRadioVo)
          // 获取图表数据
          this.currentChart = resData.storeMonthRentList
          // 2.获取对比店铺数据
          if (this.compareStore.storeCode) {
            const params2 = {
              projectId: this.filtroProject,
              storeCode: this.compareStore.storeCode,
              rentStartMonth: this.startTime,
              rentEndMonth: this.endTime,
              toCompareStoreCode: this.filtroStore
            }
            this.axios.get(api.getStoreRentCompareByCondition, { params: params2 }).then(res => {
              if (res.data.code === 0) {
                const resData = res.data.data
                // 获取支付渠道对比
                this.compareData = []
                this.compareData.push(resData.rentItemCompareRadioVo)
                // 获取图表数据
                this.compareChart = resData.storeMonthRentList
                this.getContrast() // 支付渠道数据赋值处理
                this.showChart() // 图表
              }
            })
          } else {
            this.getContrast() // 支付渠道数据赋值处理
            this.showChart() // 图表
          }
        } else {
          this.isShowChart = false
          this.setDefaultChart()
          this.showChart() // 图表
        }
      })
    },
    setIndicator (val) {
      this.indicatorValue = val
      this.showChart()
    },
    selectTimeRange (val) {
      this.timeType = val
      const end = new Date()
      const start = new Date()
      end.setTime(end.getTime() - 3600 * 1000 * 24 * 30)
      this.endTime = dateFormat('yyyy-MM', end)
      this.endTimeBak = dateFormat('yyyy-MM', end)
      switch (val) {
        case '近一年':
          start.setTime(start.getTime() - 3600 * 1000 * 24 * 365)
          this.startTime = dateFormat('yyyy-MM', start)
          this.startTimeBak = dateFormat('yyyy-MM', start)
          this.noEdit = true
          this.getData()
          break
        case '本年度':
          start.setFullYear(start.getFullYear(), 0, 1)
          this.startTime = dateFormat('yyyy-MM', start)
          this.startTimeBak = dateFormat('yyyy-MM-dd', start)
          this.noEdit = true
          this.getData()
          break
        case '自定义':
          start.setTime(start.getTime() - 3600 * 1000 * 24 * 365)
          this.startTime = dateFormat('yyyy-MM', start)
          this.startTimeBak = dateFormat('yyyy-MM', start)
          this.noEdit = false
          this.getData()
          break
      }
    },
    selectCompareStore (val) {
      const Arr = this.storeList.filter(item => {
        return item.storeName === val
      })
      this.compareStore.storeCode = Arr[0].storeCode
      this.compareStore.storeName = Arr[0].storeName
      this.getData()
    },
    setStartTime () {
      const start = new Date(this.startTime).getTime()
      const end = new Date(this.endTime).getTime()
      const difference = (end - start) / (1000 * 60 * 60 * 24)
      if (this.endTime) {
        if (start > end) {
          this.startTime = JSON.parse(JSON.stringify(this.startTimeBak)) // 回显
          this.$message({
            type: 'warning',
            message: '开始时间不能大于结束时间'
          })
          return false
        }
        if (difference > 365 * 3) {
          this.startTime = JSON.parse(JSON.stringify(this.startTimeBak)) // 回显
          this.$message({
            type: 'warning',
            message: '时间范围最大不超过3年'
          })
          return false
        }
        this.startTimeBak = this.startTime
        this.getData()
      }
    },
    setEndTime () {
      const start = new Date(this.startTimeA).getTime()
      const end = new Date(this.endTimeA).getTime()
      const difference = (end - start) / (1000 * 60 * 60 * 24)
      if (this.startTime) {
        if (start > end) {
          this.endTime = JSON.parse(JSON.stringify(this.endTimeBak)) // 回显
          this.$message({
            type: 'warning',
            message: '开始时间不能大于结束时间'
          })
          return false
        }
        if (difference > 365 * 3) {
          this.endTime = JSON.parse(JSON.stringify(this.endTimeBak)) // 回显
          this.$message({
            type: 'warning',
            message: '时间范围最大不超过3年'
          })
          return false
        }
        this.endTimeBak = this.endTime
        this.getData()
      }
    },
    showChart () {
      switch (this.indicatorValue) {
        case 0: // 租金
          // 图表数据处理
          if ((this.currentChart && this.currentChart.length > 0) || (this.compareChart && this.compareChart.length > 0)) {
            const obj = {
              indicator: '租金', // y轴名
              unit: '万元', // y轴名单位
              store1: this.currentStore.storeName ? this.currentStore.storeName : '-', // 本店铺名字
              store2: this.compareStore.storeName ? this.compareStore.storeName : '-', // 对比店铺名字
              label: [], // 横坐标
              value1: [], // 第一条线的值
              value2: [] // 第二条线的值
            }
            if (this.currentChart && this.currentChart.length > 0) {
              for (let i = 0; i < this.currentChart.length; i++) {
                obj.label.push(this.currentChart[i].measureMonth)
                obj.value1.push((this.currentChart[i].rentMoney || this.currentChart[i].rentMoney === 0) ? (this.currentChart[i].rentMoney / 10000).toFixed(2) : '') // 单位转换，元转万元
              }
            }
            if (this.compareChart && this.compareChart.length > 0) {
              for (let i = 0; i < this.compareChart.length; i++) {
                obj.value2.push((this.currentChart[i].rentMoney || this.currentChart[i].rentMoney === 0) ? (this.compareChart[i].rentMoney / 10000).toFixed(2) : '') // 单位转换，元转万元
              }
            }
            this.getChart(obj)
          } else {
            this.isShowChart = false
            this.setDefaultChart()
          }
          break
        case 1: // 租金坪效
          // 图表数据处理
          if ((this.currentChart && this.currentChart.length > 0) || (this.compareChart && this.compareChart.length > 0)) {
            const obj = {
              indicator: '租金坪效', // y轴名
              unit: '元/月/㎡', // y轴名单位
              store1: this.currentStore.storeName ? this.currentStore.storeName : '-', // 本店铺名字
              store2: this.compareStore.storeName ? this.compareStore.storeName : '-', // 对比店铺名字
              label: [], // 横坐标
              value1: [], // 第一条线的值
              value2: [] // 第二条线的值
            }
            if (this.currentChart && this.currentChart.length > 0) {
              for (let i = 0; i < this.currentChart.length; i++) {
                obj.label.push(this.currentChart[i].measureMonth)
                obj.value1.push(this.currentChart[i].rentEffect)
              }
            }
            if (this.compareChart && this.compareChart.length > 0) {
              for (let i = 0; i < this.compareChart.length; i++) {
                obj.value2.push(this.compareChart[i].rentEffect)
              }
            }
            this.getChart(obj)
          } else {
            this.isShowChart = false
            this.setDefaultChart()
          }
          break
        case 2: // '租售比'
          // 图表数据处理
          if ((this.currentChart && this.currentChart.length > 0) || (this.compareChart && this.compareChart.length > 0)) {
            const obj = {
              indicator: '租售比', // y轴名
              unit: '%', // y轴名单位
              store1: this.currentStore.storeName ? this.currentStore.storeName : '-', // 本店铺名字
              store2: this.compareStore.storeName ? this.compareStore.storeName : '-', // 对比店铺名字
              label: [], // 横坐标
              value1: [], // 第一条线的值
              value2: [] // 第二条线的值
            }
            if (this.currentChart && this.currentChart.length > 0) {
              for (let i = 0; i < this.currentChart.length; i++) {
                obj.label.push(this.currentChart[i].measureMonth)
                obj.value1.push(this.currentChart[i].salesRentRatio)
              }
            }
            if (this.compareChart && this.compareChart.length > 0) {
              for (let i = 0; i < this.compareChart.length; i++) {
                obj.value2.push(this.compareChart[i].salesRentRatio)
              }
            }
            this.getChart(obj)
          } else {
            this.isShowChart = false
            this.setDefaultChart()
          }
          break
      }
    },
    // 画图
    getChart (obj) {
      // console.log('obj', obj)
      this.charts = echarts.init(document.getElementById('charts'))
      this.charts.setOption(this.chartsOptionLine(obj))
      this.charts.setOption({
        legend: {
          data: [obj.store1, obj.store2]
        },
        xAxis: [
          {
            data: obj.label
          }
        ],
        series: [
          {
            name: obj.store1,
            type: 'line',
            smooth: true,
            emphasis: {
              focus: 'series'
            },
            lineStyle: {
              color: '#3F8CFF'
            },
            areaStyle: {
              opacity: 1,
              color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [{
                offset: 0,
                color: 'rgba(62, 140, 255, .3)'
              }, {
                offset: 1,
                color: 'rgba(62, 140, 255, 0)'
              }])
            },
            data: obj.value1
          },
          {
            name: obj.store2,
            type: 'line',
            smooth: true,
            emphasis: {
              focus: 'series'
            },
            lineStyle: {
              color: 'rgba(160, 215, 231, 1)'
            },
            areaStyle: {
              opacity: 1,
              color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [{
                offset: 0,
                color: 'rgba(160, 215, 231, .3)'
              }, {
                offset: 1,
                color: 'rgba(160, 215, 231, 0)'
              }])
            },
            data: obj.value2
          }
        ]
      })
      this.$nextTick(() => {
        window.addEventListener('resize', () => {
          this.charts.resize()
        })
      })
    },
    chartsOptionLine (obj) {
      const maxValue = this.isShowChart ? null : 1750
      const option = {
        color: ['#3F8CFF', '#A0D7E7'],
        legend: {
          itemHeight: 10,
          itemWidth: 10,
          itemGap: 30,
          top: 2,
          right: 20,
          icon: 'circle',
          textStyle: {
            color: 'rgba(255, 255, 255, .45)',
            fontFamily: 'PingFang SC',
            fontSize: 12
          }
        },
        grid: {
          top: '15%',
          left: '5%',
          right: '5%',
          bottom: '1%',
          containLabel: true
        },
        tooltip: {
          trigger: 'axis',
          backgroundColor: 'rgba(0,0,0,0.85)',
          textStyle: {
            fontSize: 12
          },
          formatter: (params) => {
            let html = '<div style="padding:4px; font-family: PingFang SC;">' + '<p style="color:#b2b3bd; text-align:center;">' + params[0].name + '</p>'
            for (let i = 0; i < params.length; i++) {
              html += '<span style="color:#b2b3bd">' + params[i].marker + params[i].seriesName + '： ' + '</span>' + ((params[i].data || params[i].data === 0) ? params[i].data : '-') + obj.unit + '<br>'
            }
            html += '</div>'
            return html
          }
        },
        xAxis: {
          boundaryGap: false,
          type: 'category',
          offset: 6,
          axisTick: {
            show: false
          },
          axisLabel: {
            color: '#808191'
          },
          axisLine: {
            lineStyle: {
              color: '#373A43'
            }
          },
          axisPointer: {
            lineStyle: {
              color: 'rgba(255,255,255,.1)'
            }
          },
          data: []
        },
        yAxis: [{
          name: obj.indicator + '(' + obj.unit + ')',
          nameTextStyle: {
            color: '#808191',
            fontFamily: 'PingFang SC',
            fontSize: 12
          },
          min: 0,
          max: maxValue,
          type: 'value',
          axisTick: {
            show: false
          },
          splitLine: {
            lineStyle: {
              color: '#373A43',
              type: 'dashed'
            }
          },
          axisLine: {
            lineStyle: {
              color: '#373A43'
            }
          },
          axisLabel: {
            color: '#808191',
            formatter: '{value}'
          }
        }],
        series: []
      }
      return option
    },
    // 进度条数据处理
    getContrast () {
      // 本店铺
      if (this.currentData[0]) {
        this.contrastList[0].percent1 = this.currentData[0].fixedRentRatio // 固定租金
        this.contrastList[0].progress1 = this.currentData[0].fixedRentRatio // 固定租金
        this.contrastList[1].percent1 = this.currentData[0].takeRentRatio // 提成租金
        this.contrastList[1].progress1 = this.currentData[0].takeRentRatio // 提成租金
      } else {
        // 重置数据
        this.contrastList[0].percent1 = '-%'
        this.contrastList[0].progress1 = '0%'
        this.contrastList[1].percent1 = '-%'
        this.contrastList[1].progress1 = '0%'
      }
      // 对比店铺
      if (this.compareData[0]) {
        this.contrastList[0].percent2 = this.compareData[0].fixedRentRatio // 固定租金
        this.contrastList[0].progress2 = this.compareData[0].fixedRentRatio // 固定租金
        this.contrastList[1].percent2 = this.compareData[0].takeRentRatio // 提成租金
        this.contrastList[1].progress2 = this.compareData[0].takeRentRatio // 提成租金
      } else {
        // 重置数据
        this.contrastList[0].percent2 = '-%'
        this.contrastList[0].progress2 = '0%'
        this.contrastList[1].percent2 = '-%'
        this.contrastList[1].progress2 = '0%'
      }
    }
  }
}
</script>

<style lang="stylus" scoped>
.view1
  padding-top 60px
  background #272930
  opacity 1
  border-radius 0 0 2px 2px
  .selectBtn-view
    margin-top 17px
    padding-left 24px
    line-height 33px
    display flex
    flex-flow wrap
    .type-item
      margin-top 15px
      display flex
      font-size 14px
      font-family PingFang SC
      color #B2B3BD
      .type-item-btn
        padding 0 3px
        height 33px
        border 1px solid #373A43
        opacity 1
        border-radius 2px
        span
          display inline-block
          width 77px
          height 27px
          line-height 27px
          font-size 14px
          font-family PingFang SC
          font-weight 500
          color rgba(255, 255, 255, 0.5)
          text-align center
          margin-right -1px
          cursor pointer
          &.active
            background #FFA134
            color #fff
            border-color #FFA134
        span:first-child
          border-radius 2px 0px 0px 2px
        span:last-child
          border-radius 0px 2px 2px 0px
      .type-item-select
        background-color #373A43
        .segmentation
          color #424752
        .isDisabled
          color rgba(128, 129, 145, 0.5)
        span
          color #B2B3BD
        & >>> .el-select
          width 154px
        & >>> .el-date-editor
          width 100px
          // width 120px
          input
            text-align center
      .timeLimit >>> .el-select
        width 112px
      .no-select >>> .el-input__suffix
        i
          display none
    .VS
      width 42px
      text-align center
      font-weight 800
      color #808191
  .contrast-checkBox
    position relative
    width 100%
  .contrast
    display flex
    margin-top 63px
    height 590px
    .contrast-data
      width 40%
      ul
        font-size 12px
        font-family PingFang SC
        font-weight 400
        color #808191
        li
          margin-right 24px
          span
            display inline-block
            width 8px
            height 8px
            border-radius 50%
            background #3F8CFF
          &:nth-child(2) span
            background #A0D7E7
      .contrast-content
        margin 40px 24px auto 24px
        .contrast-content-title
          justify-content space-between
          display flex
          margin-bottom 15px
          span
            font-size 16px
            font-family PingFang SC
            color #FFFFFF
          span:nth-child(2)
            font-size 14px
            font-weight 400
            color #B2B3BD
    .contrast-chart
      border-right 1px solid #373A43
      width 60%
      .charts-box
        top -24px
        width 100%
        height 550px
  .contrast-title
    padding-left 24px
    font-size 16px
    font-family PingFang SC
    font-weight bold
    color #FFFFFF
.mr-70
  margin-right 70px
// elm-ui样式
// 1.下拉框
/deep/ .el-input__inner
  height 33px
  background-color #373A43
  color #808191
  border none
  border-radius 2px
  padding 0 18px
// 2.日期组件样式
// （1）日期icon
/deep/ .el-input__prefix
  display none
/deep/ .el-date-editor .el-input__suffix
  display none
// （2）输入框禁止点击时
/deep/ .is-disabled .el-input__inner
  color rgba(128, 129, 145, 0.5) !important
  background-color #373a43 !important
</style>
