<template>
  <div>
    <div class="view1">
      <div class="selectBtn-view">
        <div class="type-item mr-70">
          排名指标：
          <div class="type-item-btn">
            <span
              @click="setIndicatorA('租金')"
              :class="[indicatorA === '租金' ? 'active': '']"
            >租金</span>
            <span
              @click="setIndicatorA('租金坪效')"
              :class="[indicatorA === '租金坪效' ? 'active': '']"
            >租金坪效</span>
            <span
              @click="setIndicatorA('租售比')"
              :class="[indicatorA === '租售比' ? 'active': '']"
            >租售比</span>
          </div>
        </div>
        <div class="type-item mr-70">
          业务范围：
          <div class="type-item-btn">
            <span
              @click="setIndicatorB('同业态')"
              :class="[indicatorB === '同业态' ? 'active': '']"
            >同业态</span>
            <span
              @click="setIndicatorB('全部业态')"
              :class="[indicatorB === '全部业态' ? 'active': '']"
            >全部业态</span>
          </div>
        </div>
        <div class="type-item mr-70">
          楼层范围：
          <div class="type-item-btn">
            <span
              @click="setIndicatorC('同楼层')"
              :class="[indicatorC === '同楼层' ? 'active': '']"
            >同楼层</span>
            <span
              @click="setIndicatorC('全部楼层')"
              :class="[indicatorC === '全部楼层' ? 'active': '']"
            >全部楼层</span>
          </div>
        </div>
        <div class="type-item">
          时间范围：
          <div class="type-item-select">
            <el-select
              popper-class="w-block-select-down  yellow-select-panel"
              v-model="timeType"
              size="small"
              placeholder="请选择"
              @change="handleData"
            >
              <el-option
                v-for="item in timeOptions"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              >
              </el-option>
            </el-select>
            <span class="segmentation">|</span>
            <!-- 月榜 -->
            <el-date-picker
              v-if="timeType === '月榜'"
              popper-class="orange-el-date-picker"
              v-model="monthTime"
              type="month"
              placeholder="选择日期"
              value-format="yyyy-MM"
              format="yyyy-MM"
              :picker-options="pickerOptions_month"
              @change="setMonth()"
            >
            </el-date-picker>
            <!--季度时间选择控件 -->
            <jidu-date-picker
              v-if="timeType === '季榜'"
              @quarter="quarter"
              :banDate="banDate"
              :quarterTime="quarterTime"
            ></jidu-date-picker>
            <!-- 年榜 -->
            <el-date-picker
              v-if="timeType === '年榜'"
              popper-class="orange-el-date-picker"
              v-model="yearTime"
              type="year"
              placeholder="选择日期"
              value-format="yyyy"
              format="yyyy"
              :picker-options="pickerOptions_year"
              @change="setYear()"
            >
            </el-date-picker>
          </div>
        </div>
      </div>
      <div class="tableChart">
        <div
          class="w-table"
          :class="showRankList?'w-singed-table':'grey-singed-table'"
        >
          <table v-if="showRankList">
            <thead>
              <tr>
                <th width="8.44%">排名</th>
                <th width="19.39%">店铺名称</th>
                <th width="21.90%">业态</th>
                <th width="11.76%">楼层</th>
                <th
                  v-if="indicatorA==='租金'"
                  width="9.78%"
                >租金(元)</th>
                <th
                  v-if="indicatorA==='租金坪效'"
                  width="9.78%"
                >租金坪效(元/月/㎡)</th>
                <th
                  v-if="indicatorA==='租售比'"
                  width="9.78%"
                >租售比(%)</th>
                <th width="28.80%"></th>
              </tr>
            </thead>
            <tbody>
              <tr
                v-for="(item, index) in tableData"
                :key="index"
                :id="item.isMark?'mark':''"
                :class="item.isMark?'mark':''"
                @click="toBaseClick(item)"
              >
                <!-- 排名前三的用特有的图片 -->
                <td v-if="item.rankIndex === 1">
                  <img src="@/assets/images/storeEarlywarning/salesAnalysis/number1.png">
                </td>
                <td v-else-if="item.rankIndex === 2">
                  <img src="@/assets/images/storeEarlywarning/salesAnalysis/number2.png">
                </td>
                <td v-else-if="item.rankIndex === 3">
                  <img src="@/assets/images/storeEarlywarning/salesAnalysis/number3.png">
                </td>
                <td v-else>{{item.rankIndex}}</td>
                <td>{{item.storeName}}</td>
                <td>{{item.formatsName}}</td>
                <td>{{item.floorName}}</td>
                <td :class="item.isMark?'mark':''">{{indicatorA==='租售比'?(item.indicator * 100):item.indicator}}</td>
                <td>
                  <div class="singleProgress">
                    <div class="progress-bg">
                      <div
                        :style="{
                          'width': item.percent + '%',
                          'animation': item.animation
                        }"
                        class="progress"
                      >
                      </div>
                    </div>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
          <!-- 无数据省缺时 -->
          <table v-if="!showRankList">
            <thead>
              <tr>
                <th width="8.44%">排名</th>
                <th width="19.39%">店铺名称</th>
                <th width="21.90%">业态</th>
                <th width="11.76%">楼层</th>
                <th
                  v-if="indicatorA==='租金'"
                  width="9.78%"
                >租金(元)</th>
                <th
                  v-if="indicatorA==='租金坪效'"
                  width="9.78%"
                >租金坪效(元/月/㎡)</th>
                <th
                  v-if="indicatorA==='租售比'"
                  width="9.78%"
                >租售比(%)</th>
                <th width="30%"></th>
              </tr>
            </thead>
            <tbody>
              <tr
                v-for="(item, index) in tableNoData"
                :key="index"
              >
                <!-- 排名前三的用特有的图片 -->
                <td v-if="item.rankIndex === 1">
                  <img src="@/assets/images/storeEarlywarning/salesAnalysis/number1.png">
                </td>
                <td v-else-if="item.rankIndex === 2">
                  <img src="@/assets/images/storeEarlywarning/salesAnalysis/number2.png">
                </td>
                <td v-else-if="item.rankIndex === 3">
                  <img src="@/assets/images/storeEarlywarning/salesAnalysis/number3.png">
                </td>
                <td v-else>{{item.rankIndex}}</td>
                <td>{{item.storeName}}</td>
                <td>{{item.formatsName}}</td>
                <td>{{item.floorName}}</td>
                <td>{{item.indicator}}</td>
                <td>
                  <div class="singleProgress">
                    <div class="progress-bg"></div>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <div class="chartView">
          <div class="rank">
            <div class="title">
              <img
                src="@/assets/images/storeEarlywarning/salesAnalysis/rank.png"
                alt=""
              >
              <span>本周期排名</span>
            </div>
            <div v-show="showRankData">
              <div class="chart">
                <div class="number">{{rankNumber?rankNumber:'-'}}</div>
                <div
                  id="charts1"
                  class="chart-box"
                ></div>
              </div>
              <div class="comparison">
                相对上周期({{lastPeriodTime}})：
                <span>
                  {{LastRankData ? (rankNumberLast ? rankNumberLast + '名':'持平') : '-'}}
                  <i :class="isChangeRank === 2 ? 'icon-data-up': (isChangeRank === 1 ?'icon-data-down' : '')"></i>
                </span>
              </div>
              <div
                v-if="timeType !== '年榜'"
                class="comparison"
              >
                相对去年同期({{lastYearPeriodTime}})：
                <span>
                  {{lastYearRank ? (rankNumberLastYear  ?rankNumberLastYear + '名':'持平') : '-'}}
                  <i :class="isChangeRankYear === 2 ? 'icon-data-up': (isChangeRankYear === 1 ?'icon-data-down' : '')"></i>
                </span>
              </div>
            </div>
            <div
              class="no-rank-data"
              v-show="!showRankData"
            >
              <img
                src="@/assets/images/public/not-plandata.png"
                alt=""
              >
              <p>暂无数据</p>
            </div>
          </div>
          <div class="rent">
            <div class="title">
              <img
                src="@/assets/images/storeEarlywarning/salesAnalysis/money.png"
                alt=""
              >
              <span>本周期{{indicatorA}}</span>
              <span v-if="indicatorA==='租金'">(万元)</span>
              <span v-if="indicatorA==='租金坪效'">(元/月/㎡)</span>
              <span v-if="indicatorA==='租售比'">(%)</span>
            </div>
            <div v-show="showIndicatorData">
              <div class="chart">
                <div class="number">{{indicatorData || indicatorData === 0?indicatorData:'-'}}</div>
                <div
                  id="charts2"
                  class="chart-box"
                ></div>
              </div>
              <div class="comparison">
                相对排名{{reputation}}的{{numberOneStoreName?numberOneStoreName:'商户'}}{{indicatorA}}：
                <span>
                  {{numberOneStoreName?(indicatorDataLast?indicatorDataLast:'持平'): '-'}}
                  <!-- 这个箭头方向以后改进 -->
                  <!-- 如果对比的是第一名的时候 -->
                  <i
                    v-if="reputation === '第一'"
                    :class="isChangeIndicator === 2 ? 'icon-data-up': (isChangeIndicator === 1 ?'icon-data-down' : '')"
                  ></i>
                  <!-- 如果对比的是第二名的时候 -->
                  <i
                    v-if="reputation === '第二'"
                    :class="isChangeIndicator === 1 ? 'icon-data-up': (isChangeIndicator === 2 ?'icon-data-down' : '')"
                  ></i>
                </span>
              </div>
              <div class="comparison">
                相对排名内商户的平均{{indicatorA}}：
                <span>
                  {{indicatorDataLastYear?indicatorDataLastYear:'持平'}}
                  <i
                    v-if="indicatorDataLastYear"
                    :class="isChangeIndicatorYear === 1 ? 'icon-data-up': (isChangeIndicatorYear === 2 ?'icon-data-down' : '')"
                  ></i>
                </span>
              </div>
            </div>
            <div
              class="no-rank-data"
              v-show="!showIndicatorData"
            >
              <img
                src="@/assets/images/public/not-plandata.png"
                alt=""
              >
              <p>暂无数据</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import api from '@/api'
import jiduDatePicker from '@/views/storeEarlywarning/components/jududatepicker.vue'
import echarts from 'echarts'
export default {
  name: 'salesRank',
  data () {
    return {
      indicatorA: '租金',
      indicatorB: '同业态',
      indicatorC: '同楼层',
      charts1: {},
      charts2: {},
      tableAllData: [],
      tableData: [],
      tableNoData: [],
      storeName: '', // 本店铺名
      numberOneStoreName: '', // 排名第一的店铺名
      rankData: {},
      currentRankData: '', // 现在的数据
      LastRankData: '',
      lastYearRank: '', // 上一年的数据
      lastPeriodTime: '', // 上周期时间
      lastYearPeriodTime: '', // 上年周期时间
      // 本周期排名
      rankNumber: '', // 排名数值
      isChangeRank: 3, // 显示增减或持平，1为降、2为增、3为不变
      isChangeRankYear: 3, // // 显示增减或持平，1为降、2为增、3为不变
      rankNumberLast: '', // 相对上周期的排名的增值或降值
      rankNumberLastYear: '', // 相对上年周期的排名的增值或降值
      // 排名指标
      indicatorData: '', // 排名指标的值
      isChangeIndicator: 3, // 显示增减或持平，1为增、2为降、3为不变
      isChangeIndicatorYear: 3, // 显示增减或持平，1为增、2为降、3为不变
      indicatorDataLast: '', // 相对上周期的排名指标的增值或降值
      indicatorDataLastYear: '', // 相对上年周期的排名指标的增值或降值
      reputation: '第一',
      timeType: '月榜',
      timeOptions: [{
        value: '月榜',
        label: '月榜'
      }, {
        value: '季榜',
        label: '季榜'
      }, {
        value: '年榜',
        label: '年榜'
      }],
      rankType: '',
      currentTime: '',
      markIndex: '',
      monthTime: '',
      quarterTime: '',
      yearTime: '',
      banDate: '',
      pickerOptions_month: {
        disabledDate: (time) => {
          const t = new Date().getDate()
          return time.getTime() < this.banDate || time.getTime() >= (Date.now() - 8.64e7 * t)
        }
      },
      pickerOptions_year: {
        disabledDate: (time) => {
          return time.getTime() < (this.banDate - 8.64e7 * 365) || time.getTime() >= (Date.now() - 8.64e7 * 365)
        }
      },
      showRankData: true,
      showIndicatorData: true,
      showRankList: true
    }
  },
  components: {
    jiduDatePicker
  },
  props: {
    filtroProject: {
      type: Number
    },
    filtroStore: {
      type: String
    },
    storeOptions: {
      type: Array
    }
  },
  watch: {
    filtroStore (newValue, oldValue) {
      this.getData()
    }
  },
  mounted () {
    this.setDefaultDate()
    this.getBanDate()
    this.getStoreName()
    this.getChart()
    this.setCurrentTime()
    this.getData()
  },
  methods: {
    setDefaultDate () {
      for (let i = 0; i < 10; i++) {
        const obj = {
          storeName: '-',
          formatsName: '-',
          floorName: '-',
          indicator: '-',
          rankIndex: i + 1
        }
        this.tableNoData.push(obj)
      }
    },
    getBanDate () {
      const Arr = this.storeOptions.filter(item => {
        return item.storeCode === this.filtroStore
      })
      if (Arr && Arr.length > 0) {
        const date = new Date(Arr[0].rentBeg)
        this.banDate = date.getTime()
      }
    },
    getStoreName () {
      const Arr = this.storeOptions.filter(item => {
        return item.storeCode === this.filtroStore
      })
      if (Arr && Arr.length > 0) {
        this.storeName = Arr[0].storeName
      }
    },
    getData () {
      // 重置数据
      this.tableData = []
      this.tableAllData = []
      this.rankNumber = ''
      this.isChangeRank = 3
      this.isChangeRankYear = 3
      this.rankNumberLast = ''
      this.rankNumberLastYear = ''
      this.indicatorData = ''
      this.isChangeIndicator = 3
      this.isChangeIndicatorYear = 3
      this.indicatorDataLast = ''
      this.indicatorDataLastYear = ''
      this.currentRankData = ''
      this.LastRankData = ''
      this.lastYearRank = ''
      this.showRankData = true
      this.showIndicatorData = true
      // 处理时间
      switch (this.timeType) {
        case '月榜':
          this.rankType = 'month'
          this.currentTime = this.monthTime
          break
        case '季榜':
          this.rankType = 'quarter'
          this.currentTime = this.quarterTime
          break
        case '年榜':
          this.rankType = 'year'
          this.currentTime = this.yearTime
          break
      }
      // 获取数据并处理
      const params = {
        projectId: this.filtroProject,
        storeCode: this.filtroStore,
        rankDate: this.currentTime,
        rankType: this.rankType
      }
      this.axios.get(api.getStoreRentRank, { params: params }).then(res => {
        if (res.data.code === 0) {
          this.showRankList = true
          const resData = res.data.data
          // 排行数据
          if (resData.currentStoreRank) {
            this.currentRankData = resData.storeRentRankList[resData.currentStoreRank - 1] // 当前
            this.LastRankData = resData.storePrePeriodRentRank // 上周期
            this.lastYearRank = resData.storeSamePeriodRentRank // 上年
            // 获取列表排行
            if (resData.storeRentRankList.length > 0) {
              this.tableAllData = resData.storeRentRankList.map((item) => {
                return {
                  storeName: item.storeName, // 店铺名称
                  storeCode: item.storeCode, // 店铺id
                  formatsName: item.formatsName, // 业态名称
                  formats1Code: item.formats1Code, // 一级业态编码
                  floorCode: item.floorCode, // 楼层编码
                  floorName: item.floorName, // 楼层名字
                  rentMoney: item.rentMoney, // 租金
                  rentEffect: item.rentEffect, // 租金坪效
                  salesRentRatio: item.salesRentRatio, // 租售比
                  isMark: item.currentStore === '1', // 是否是当前
                  // 租金排名
                  ytlcRentMoneyRank: item.ytlcRentMoneyRank, // 同业态同楼层租金排行
                  ytRentMoneyRank: item.ytRentMoneyRank, //  同业态租金排行
                  lcRentMoneyRank: item.lcRentMoneyRank, // 同楼层租金排行
                  rentMoneyRank: item.rentMoneyRank, // 租金排行
                  // 租金坪效排名
                  ytlcRentEffectRank: item.ytlcRentEffectRank, // 同业态同楼层租金坪效排行
                  ytRentEffectRank: item.ytRentEffectRank, //  同业态租金坪效排行
                  lcRentEffectRank: item.lcRentEffectRank, // 同楼层租金坪效排行
                  rentEffectRank: item.rentEffectRank, // 租金坪效排行
                  // 租售比排名
                  ytlcSalesRentRatioRank: item.ytlcSalesRentRatioRank, // 同业态同楼层租售比排行
                  ytSalesRentRatioRank: item.ytSalesRentRatioRank, //  同业态租售比排行
                  lcSalesRentRatioRank: item.lcSalesRentRatioRank, // 同楼层租售比排行
                  salesRentRatioRank: item.salesRentRatioRank, // 租售比排行
                  validFlag: item.validFlag //  合同生效失效标识。1-生效中, 2-已失效
                }
              })
              // this.showRank()
            }
          } else {
            this.showRankList = false
          }
          this.showRank()
        } else {
          this.showRankList = false
        }
      })
    },
    setIndicatorA (val) {
      this.indicatorA = val
      if (this.tableData.length) {
        this.showRank()
      }
    },
    setIndicatorB (val) {
      this.indicatorB = val
      this.showRank()
    },
    setIndicatorC (val) {
      this.indicatorC = val
      this.showRank()
    },
    handleData (val) {
      this.timeType = val
      switch (val) {
        case '月榜':
          this.rankType = 'month'
          this.setMonth()
          break
        case '季榜':
          this.rankType = 'quarter'
          this.quarter()
          break
        case '年榜':
          this.rankType = 'year'
          this.setYear()
          break
      }
    },
    setCurrentTime () {
      const date = new Date()
      const year = date.getFullYear() // 获取本年
      const seasonYear = date.getFullYear() // 获取本年,计算季度用,为了区分上面的年，另生成一个
      const month = date.getMonth() + 1 // 本月月份
      const lastMonth = month - 1 // 上个月月份
      // 设置默认为上月
      this.monthTime = year + '-' + this.appendZero(lastMonth)
      // 设置默认季度为上季度
      if (month <= 3) { // 4季度
        const season = 4
        const lastYear = seasonYear - 1
        this.quarterTime = lastYear + 'Q' + season
      } else if (month > 3 && month <= 6) { // 1季度
        const season = 1
        this.quarterTime = seasonYear + 'Q' + season
      } else if (month > 6 && month <= 9) { // 2季度
        const season = 2
        this.quarterTime = seasonYear + 'Q' + season
      } else if (month > 9 && month <= 12) { // 3季度
        const season = 3
        this.quarterTime = seasonYear + 'Q' + season
      }
      // 设置默认日为上年
      this.yearTime = (year - 1).toString()
      // 上周期时间
      const Arr = this.monthTime.split('-')
      const dateLast = new Date(Arr[0], Arr[1] - 1, 0)
      const yearLast = dateLast.getMonth() === 0 ? dateLast.getFullYear() - 1 : dateLast.getFullYear()
      const monthLast = dateLast.getMonth() === 0 ? 12 : dateLast.getMonth() + 1
      const day = dateLast.getDate()
      this.lastPeriodTime = yearLast + '.' + this.appendZero(monthLast) + '.' + '01' + ' ~ ' + yearLast + '.' + this.appendZero(monthLast) + '.' + this.appendZero(day)
      // 上年周期
      const dateLastYear = new Date(Arr[0], Arr[1], 0)
      const lastYear = dateLastYear.getFullYear() - 1
      const lastYearMonth = dateLastYear.getMonth() === 0 ? 1 : dateLastYear.getMonth() + 1
      const lastDay = dateLastYear.getDate()
      this.lastYearPeriodTime = lastYear + '.' + this.appendZero(lastYearMonth) + '.' + '01' + ' ~ ' + lastYear + '.' + this.appendZero(lastYearMonth) + '.' + this.appendZero(lastDay)
    },
    // 选择季度
    quarter (res) {
      // 调用接口
      if (res) {
        this.quarterTime = res.year + 'Q' + res.season
        this.getData()
      } else {
        this.getData()
      }
      // 处理时间
      const Arr = this.quarterTime.split('Q')
      if (Arr[1] === '2') {
        // 上上个季度
        const date = new Date(Arr[0], 3, 0)
        const year = date.getFullYear()
        const dayEnd = date.getDate()
        this.lastPeriodTime = year + '.01.01' + ' ~ ' + year + '.03.' + this.appendZero(dayEnd)
        // 上一年的上个季度
        const last = new Date(Arr[0] - 1, 6, 0)
        const lastYear = last.getFullYear()
        const lastDayEnd = last.getDate()
        this.lastYearPeriodTime = lastYear + '.04.01' + ' ~ ' + lastYear + '.06.' + this.appendZero(lastDayEnd)
      } else if (Arr[1] === '3') {
        // 上上个季度
        const date = new Date(Arr[0], 6, 0)
        const year = date.getFullYear()
        const dayEnd = date.getDate()
        this.lastPeriodTime = year + '.04.01' + ' ~ ' + year + '.06.' + this.appendZero(dayEnd)
        // 上一年的上个季度
        const last = new Date(Arr[0] - 1, 7, 0)
        const lastYear = last.getFullYear()
        const lastDayEnd = last.getDate()
        this.lastYearPeriodTime = lastYear + '.05.01' + ' ~ ' + lastYear + '.07.' + this.appendZero(lastDayEnd)
      } else if (Arr[1] === '4') {
        // 上上个季度
        const date = new Date(Arr[0], 9, 0)
        const year = date.getFullYear()
        const dayEnd = date.getDate()
        this.lastPeriodTime = year + '.07.01' + ' ~ ' + year + '.09.' + this.appendZero(dayEnd)
        // 上一年的上个季度
        const last = new Date(Arr[0] - 1, 12, 0)
        const lastYear = last.getFullYear()
        const lastDayEnd = last.getDate()
        this.lastYearPeriodTime = lastYear + '.10.01' + ' ~ ' + lastYear + '.12.' + this.appendZero(lastDayEnd)
      } else if (Arr[1] === '1') {
        // 上上个季度
        const date = new Date(Arr[0], 12, 0)
        const year = date.getFullYear() - 1
        const dayEnd = date.getDate()
        this.lastPeriodTime = year + '.10.01' + ' ~ ' + year + '.12.' + this.appendZero(dayEnd)
        // 上一年的上个季度
        const last = new Date(Arr[0] - 1, 3, 0)
        const lastYear = last.getFullYear()
        const lastDayEnd = last.getDate()
        this.lastYearPeriodTime = lastYear + '.01.01' + ' ~ ' + lastYear + '.03.' + this.appendZero(lastDayEnd)
      }
    },
    // 选择月
    setMonth () {
      const Arr = this.monthTime.split('-')
      // 上周期时间
      const dateLast = new Date(Arr[0], Arr[1] - 1, 0)
      const year = dateLast.getMonth() === 0 ? dateLast.getFullYear() - 1 : dateLast.getFullYear()
      const month = dateLast.getMonth() === 0 ? 12 : dateLast.getMonth() + 1
      const day = dateLast.getDate()
      this.lastPeriodTime = year + '.' + this.appendZero(month) + '.' + '01' + ' ~ ' + year + '.' + this.appendZero(month) + '.' + this.appendZero(day)
      // 上年周期
      const date = new Date(Arr[0], Arr[1], 0)
      const lastYear = date.getFullYear() - 1
      const lastMonth = date.getMonth() === 0 ? 1 : date.getMonth() + 1
      const lastDay = date.getDate()
      this.lastYearPeriodTime = lastYear + '.' + this.appendZero(lastMonth) + '.' + '01' + ' ~ ' + lastYear + '.' + this.appendZero(lastMonth) + '.' + this.appendZero(lastDay)
      // 调用接口
      this.getData()
    },
    // 选择年
    setYear () {
      // 上周期时间
      const date = new Date(this.yearTime, 12, 0)
      const year = this.yearTime - 1
      const day = date.getDate()
      this.lastPeriodTime = year + '.01.' + '01' + ' ~ ' + year + '.12.' + this.appendZero(day)
      this.getData()
    },
    // 日期不足两位数，补0
    appendZero (obj) {
      return obj < 10 ? '0' + obj : obj
    },
    showContrast () {
      // 进度条设置加载动画
      // 这段代码可省略，改用CSS的trasision,以后优化
      this.tableData.forEach((element, index) => {
        // 设置百分比
        if (element.indicator && this.tableData[0].indicator) {
          this.tableData[index].percent = (element.indicator / this.tableData[0].indicator * 100).toFixed(2) || 0
        } else {
          this.tableData[index].percent = 0
        }
        // 设置animation
        this.tableData[index].animation = 'percent-right' + Number(index + 1) + ' ' + '2s'
        // 1.创建css keyframes
        const style = document.createElement('style')
        style.setAttribute('type', 'text/css')
        document.head.appendChild(style)
        const sheet = style.sheet
        sheet.insertRule('@keyframes percent-right' + `${index + 1}` + '{from {width: 0} to {width: ' + `${element.percent}` + '%}}', 0)
        if (element.isMark) {
          this.markIndex = index
        }
      })
      // 滑动到当前
      if (this.markIndex >= (this.tableData.length - 5) && this.markIndex <= this.tableData.length) {
        this.$nextTick(() => {
          document.getElementById('mark').scrollIntoView({
            behavior: 'smooth', // 平滑过渡
            block: 'center' // 上边框与视窗顶部平齐。默认值
          })
        })
      } else {
        this.$nextTick(() => {
          document.getElementById('mark').scrollIntoView({
            behavior: 'smooth', // 平滑过渡
            block: 'center' // 上边框与视窗顶部平齐。默认值
          })
        })
      }
    },
    // 排序，以后优化
    // 租金排行
    sortytlcRentMoneyRank (a, b) { // 同业态, 同楼层
      return a.ytlcRentMoneyRank - b.ytlcRentMoneyRank
    },
    sortytRentMoneyRank (a, b) { // 同业态, 全部楼层
      return a.ytRentMoneyRank - b.ytRentMoneyRank
    },
    sortlcRentMoneyRank (a, b) { // 全部业态, 同楼层
      return a.lcRentMoneyRank - b.lcRentMoneyRank
    },
    sortrentMoneyRank (a, b) { // 全部业态, 全部楼层
      return a.rentMoneyRank - b.rentMoneyRank
    },
    // 租金坪效排行
    sortytlcRentEffectRank (a, b) { // 同业态, 同楼层
      return a.ytlcRentEffectRank - b.ytlcRentEffectRank
    },
    sortytRentEffectRank (a, b) { // 同业态, 全部楼层
      return a.ytRentEffectRank - b.ytRentEffectRank
    },
    sortlcRentEffectRank (a, b) { // 全部业态, 同楼层
      return a.lcRentEffectRank - b.lcRentEffectRank
    },
    sortrentEffectRank (a, b) { // 全部业态, 全部楼层
      return a.rentEffectRank - b.rentEffectRank
    },
    // 租售比排名
    sortYtlcsalesRentRatioRank (a, b) { // 同业态, 同楼层
      return a.ytlcSalesRentRatioRank - b.ytlcSalesRentRatioRank
    },
    sortYtsalesRentRatioRank (a, b) { // 同业态, 全部楼层
      return a.ytSalesRentRatioRank - b.ytSalesRentRatioRank
    },
    sortLcsalesRentRatioRank (a, b) { // 全部业态, 同楼层
      return a.lcSalesRentRatioRank - b.lcSalesRentRatioRank
    },
    sortsalesRentRatioRank (a, b) { // 全部业态, 全部楼层
      return a.salesRentRatioRank - b.salesRentRatioRank
    },
    // 根据排名指标的值，进行二次排序
    sortIndicator (a, b) {
      return a.indicator - b.indicator
    },
    sortSecond (Arr) {
      let Number1 = Arr.filter(item => {
        return item.rankIndex === 1
      })
      Number1 = Number1.sort(this.sortIndicator).reverse()

      let Number2 = Arr.filter(item => {
        return item.rankIndex === 2
      })
      Number2 = Number2.sort(this.sortIndicator).reverse()

      let Number3 = Arr.filter(item => {
        return item.rankIndex === 3
      })
      Number3 = Number3.sort(this.sortIndicator).reverse()

      let NumberRest = Arr.filter(item => {
        return item.rankIndex !== 1 && item.rankIndex !== 2 && item.rankIndex !== 3
      })
      NumberRest = NumberRest.sort(this.sortIndicator)
      const ArrFinal = Number1.concat(Number2.concat(Number3.concat(NumberRest)))
      return ArrFinal
    },
    // 对比排名、指标值
    compare (a, b) {
      let value = a - b
      let type = ''
      if (value > 0) {
        type = 1
      } else if (value < 0) {
        type = 2
      } else if (value === 0) {
        type = 3
      }
      value = Math.abs(value) // 取绝对值
      return { value, type }
    },
    showRank () {
      // 先重置数据
      this.tableData = []
      this.rankNumber = ''
      this.isChangeRank = 3
      this.isChangeRankYear = 3
      this.isChangeIndicator = 3
      this.isChangeIndicatorYear = 3
      this.rankNumberLast = ''
      this.rankNumberLastYear = ''
      this.rankNumber = ''
      this.indicatorDataLast = ''
      this.indicatorDataLastYear = ''
      this.indicatorData = ''
      this.numberOneStoreName = ''
      this.showRankData = true
      this.showIndicatorData = true
      switch (this.indicatorA) {
        case '租金':
          // 一、排名数据处理
          if (this.indicatorB === '同业态' && this.indicatorC === '同楼层') {
            if (this.currentRankData) {
              this.showRankData = true
              // 一、数值处理
              // 1.获取当前排名
              this.rankNumber = this.currentRankData.ytlcRentMoneyRank
              // 2.获取对比上个周期
              if (this.LastRankData) {
                const obj = this.compare(this.currentRankData.ytlcRentMoneyRank, this.LastRankData.ytlcRentMoneyRank) // 用现在的排名减过去的排名
                this.isChangeRank = obj.type
                this.rankNumberLast = obj.value
              }
              // 3.获取对比上年年周期
              if (this.lastYearRank) {
                const obj = this.compare(this.currentRankData.ytlcRentMoneyRank, this.lastYearRank.ytlcRentMoneyRank)
                this.isChangeRankYear = obj.type
                this.rankNumberLastYear = obj.value
              }
              // 二、列表排序处理, 排序方法以后优化
              // 1.筛选
              const filterArr = this.tableAllData.filter(item => {
                return item.formats1Code === this.currentRankData.formats1Code && item.floorCode === this.currentRankData.floorCode
              })
              // 2.根据排名数，进行1次排序
              const sortArr1 = filterArr.sort(this.sortytlcRentMoneyRank)
              // 3.根据指标值，进行2次排序
              const sortArr2 = this.sortSecond(sortArr1)
              // 4.赋值展示
              this.tableData = sortArr2.map((item) => {
                return {
                  storeName: item.storeName,
                  isMark: item.isMark,
                  formatsName: item.formatsName,
                  floorName: item.floorName,
                  indicator: item.rentMoney,
                  rankIndex: item.ytlcRentMoneyRank,
                  storeCode: item.storeCode,
                  validFlag: item.validFlag
                }
              })
              this.showContrast()
            } else {
              this.showRankData = false
            }
          } else if (this.indicatorB === '全部业态' && this.indicatorC === '同楼层') {
            if (this.currentRankData) {
              this.showRankData = true
              // 一、数值处理
              // 1.获取当前排名
              this.rankNumber = this.currentRankData.lcRentMoneyRank
              // 2.获取对比上个周期
              if (this.LastRankData) {
                const obj = this.compare(this.currentRankData.lcRentMoneyRank, this.LastRankData.lcRentMoneyRank) // 用现在的排名减过去的排名
                this.isChangeRank = obj.type
                this.rankNumberLast = obj.value
              }
              // 3.获取对比上年年周期
              if (this.lastYearRank) {
                const obj = this.compare(this.currentRankData.lcRentMoneyRank, this.lastYearRank.lcRentMoneyRank)
                this.isChangeRankYear = obj.type
                this.rankNumberLastYear = obj.value
              }
              // 二、列表排序处理, 排序方法以后优化
              // 1.筛选
              const filterArr = this.tableAllData.filter(item => {
                return item.floorCode === this.currentRankData.floorCode
              })
              // 2.根据排名数，进行1次排序
              const sortArr1 = filterArr.sort(this.sortlcRentMoneyRank)
              // 3.根据指标值，进行2次排序
              const sortArr2 = this.sortSecond(sortArr1)
              // 4.赋值展示
              this.tableData = sortArr2.map((item) => {
                return {
                  storeName: item.storeName,
                  isMark: item.isMark,
                  formatsName: item.formatsName,
                  floorName: item.floorName,
                  indicator: item.rentMoney,
                  rankIndex: item.lcRentMoneyRank,
                  storeCode: item.storeCode,
                  validFlag: item.validFlag
                }
              })
              this.showContrast()
            } else {
              this.showRankData = false
            }
          } else if (this.indicatorB === '同业态' && this.indicatorC === '全部楼层') {
            if (this.currentRankData) {
              this.showRankData = true
              // 一、数值处理
              // 1.获取当前排名
              this.rankNumber = this.currentRankData.ytRentMoneyRank
              // 2.获取对比上个周期
              if (this.LastRankData) {
                const obj = this.compare(this.currentRankData.ytRentMoneyRank, this.LastRankData.ytRentMoneyRank) // 用现在的排名减过去的排名
                this.isChangeRank = obj.type
                this.rankNumberLast = obj.value
              }
              // 3.获取对比上年年周期
              if (this.lastYearRank) {
                const obj = this.compare(this.currentRankData.ytRentMoneyRank, this.lastYearRank.ytRentMoneyRank)
                this.isChangeRankYear = obj.type
                this.rankNumberLastYear = obj.value
              }
              // 二、列表排序处理, 排序方法以后优化
              // 1.筛选
              const filterArr = this.tableAllData.filter(item => {
                return item.formats1Code === this.currentRankData.formats1Code
              })
              // 2.根据排名数，进行1次排序
              const sortArr1 = filterArr.sort(this.sortytRentMoneyRank)
              // 3.根据指标值，进行2次排序
              const sortArr2 = this.sortSecond(sortArr1)
              // 4.赋值展示
              this.tableData = sortArr2.map((item) => {
                return {
                  storeName: item.storeName,
                  isMark: item.isMark,
                  formatsName: item.formatsName,
                  floorName: item.floorName,
                  indicator: item.rentMoney,
                  rankIndex: item.ytRentMoneyRank,
                  storeCode: item.storeCode,
                  validFlag: item.validFlag
                }
              })
              this.showContrast()
            } else {
              this.showRankData = false
            }
          } else if (this.indicatorB === '全部业态' && this.indicatorC === '全部楼层') {
            if (this.currentRankData) {
              this.showRankData = true
              // 一、数值处理
              // 1.获取当前排名
              this.rankNumber = this.currentRankData.rentMoneyRank
              // 2.获取对比上个周期
              if (this.LastRankData) {
                const obj = this.compare(this.currentRankData.rentMoneyRank, this.LastRankData.rentMoneyRank) // 用现在的排名减过去的排名
                this.isChangeRank = obj.type
                this.rankNumberLast = obj.value
              }
              // 3.获取对比上年年周期
              if (this.lastYearRank) {
                const obj = this.compare(this.currentRankData.rentMoneyRank, this.lastYearRank.rentMoneyRank)
                this.isChangeRankYear = obj.type
                this.rankNumberLastYear = obj.value
              }
              // 二、列表排序处理, 排序方法以后优化
              // 1.筛选
              const filterArr = this.tableAllData.filter(item => {
                return item
              })
              // 2.根据排名数，进行1次排序
              const sortArr1 = filterArr.sort(this.sortrentMoneyRank)
              // 3.根据指标值，进行2次排序
              const sortArr2 = this.sortSecond(sortArr1)
              // 4.赋值展示
              this.tableData = sortArr2.map((item) => {
                return {
                  storeName: item.storeName,
                  isMark: item.isMark,
                  formatsName: item.formatsName,
                  floorName: item.floorName,
                  indicator: item.rentMoney,
                  rankIndex: item.rentMoneyRank,
                  storeCode: item.storeCode,
                  validFlag: item.validFlag
                }
              })
              this.showContrast()
            } else {
              this.showRankData = false
            }
          }
          // 二、指标值处理
          if (this.currentRankData) {
            this.showIndicatorData = true
            // 1.获取当前指标值
            this.indicatorData = (this.currentRankData.rentMoney / 10000).toFixed(2)
            // 2.获取对比第一名
            if (this.storeName === this.tableData[0].storeName) { // 如果第一名是本店铺，就跟第二名比
              if (this.tableData[1]) {
                let percent = ''
                if (this.currentRankData.rentMoney) {
                  percent = (((this.currentRankData.rentMoney - this.tableData[1].indicator) / this.currentRankData.rentMoney) * 100).toFixed(2)
                }
                const obj = this.compare(this.currentRankData.rentMoney, this.tableData[1].indicator)
                this.isChangeIndicator = obj.type
                if (percent) {
                  this.indicatorDataLast = (this.tableData[1].indicator / 10000).toFixed(2) + '万元' + ' ' + percent + '%'
                } else {
                  this.indicatorDataLast = ''
                }
                this.numberOneStoreName = JSON.parse(JSON.stringify(this.tableData[1].storeName)) // 获取排名第二的店铺名
              }
              this.reputation = '第二'
            } else { // 如果第一名不是本店铺，就跟第一名比
              let percent = ''
              if (this.tableData[0].indicator) {
                percent = (((this.tableData[0].indicator - this.currentRankData.rentMoney) / this.tableData[0].indicator) * 100).toFixed(2)
              }
              const obj = this.compare(this.tableData[0].indicator, this.currentRankData.rentMoney)
              this.isChangeIndicator = obj.type
              if (percent) {
                this.indicatorDataLast = (this.tableData[0].indicator / 10000).toFixed(2) + '万元' + ' ' + percent + '%'
              } else {
                this.indicatorDataLast = ''
              }
              // 获取排名第一的店铺名
              this.reputation = '第一'
              this.numberOneStoreName = JSON.parse(JSON.stringify(this.tableData[0].storeName))
            }
            // 3.对比平均值
            let sum = 0
            for (let i = 0; i < this.tableData.length; i++) {
              sum = sum + this.tableData[i].indicator
            }
            const average = sum / this.tableData.length
            const object = this.compare(this.currentRankData.rentMoney, average)
            this.isChangeIndicatorYear = object.type
            if (average) {
              const percent = ((Math.abs(this.currentRankData.rentMoney - average) / average) * 100).toFixed(2)
              if (percent !== '0.00') {
                this.indicatorDataLastYear = (average / 10000).toFixed(2) + '万元' + ' ' + percent + '%'
              } else {
                this.indicatorDataLastYear = ''
              }
            } else {
              this.indicatorDataLastYear = ''
            }
          } else {
            this.showIndicatorData = false
          }
          break
        case '租金坪效':
          // 一、排名数据处理
          if (this.indicatorB === '同业态' && this.indicatorC === '同楼层') {
            if (this.currentRankData) {
              this.showRankData = true
              // 一、数值处理
              // 1.获取当前排名
              this.rankNumber = this.currentRankData.ytlcRentEffectRank
              // 2.获取对比上个周期
              if (this.LastRankData) {
                const obj = this.compare(this.currentRankData.ytlcRentEffectRank, this.LastRankData.ytlcRentEffectRank) // 用现在的排名减过去的排名
                this.isChangeRank = obj.type
                this.rankNumberLast = obj.value
              }
              // 3.获取对比上年年周期
              if (this.lastYearRank) {
                const obj = this.compare(this.currentRankData.ytlcRentEffectRank, this.lastYearRank.ytlcRentEffectRank)
                this.isChangeRankYear = obj.type
                this.rankNumberLastYear = obj.value
              }
              // 二、列表排序处理, 排序方法以后优化
              // 1.筛选
              const filterArr = this.tableAllData.filter(item => {
                return item.formats1Code === this.currentRankData.formats1Code && item.floorCode === this.currentRankData.floorCode
              })
              // 2.根据排名数，进行1次排序
              const sortArr1 = filterArr.sort(this.sortytlcRentEffectRank)
              // 3.根据指标值，进行2次排序
              const sortArr2 = this.sortSecond(sortArr1)
              // 4.赋值展示
              this.tableData = sortArr2.map((item) => {
                return {
                  storeName: item.storeName,
                  isMark: item.isMark,
                  formatsName: item.formatsName,
                  floorName: item.floorName,
                  indicator: item.rentEffect,
                  rankIndex: item.ytlcRentEffectRank,
                  storeCode: item.storeCode,
                  validFlag: item.validFlag
                }
              })
              this.showContrast()
            } else {
              this.showRankData = false
            }
          } else if (this.indicatorB === '全部业态' && this.indicatorC === '同楼层') {
            if (this.currentRankData) {
              this.showRankData = true
              // 一、数值处理
              // 1.获取当前排名
              this.rankNumber = this.currentRankData.lcRentEffectRank
              // 2.获取对比上个周期
              if (this.LastRankData) {
                const obj = this.compare(this.currentRankData.lcRentEffectRank, this.LastRankData.lcRentEffectRank) // 用现在的排名减过去的排名
                this.isChangeRank = obj.type
                this.rankNumberLast = obj.value
              }
              // 3.获取对比上年年周期
              if (this.lastYearRank) {
                const obj = this.compare(this.currentRankData.lcRentEffectRank, this.lastYearRank.lcRentEffectRank)
                this.isChangeRankYear = obj.type
                this.rankNumberLastYear = obj.value
              }
              // 二、列表排序处理, 排序方法以后优化
              // 1.筛选
              const filterArr = this.tableAllData.filter(item => {
                return item.floorCode === this.currentRankData.floorCode
              })
              // 2.根据排名数，进行1次排序
              const sortArr1 = filterArr.sort(this.sortlcRentEffectRank)
              // 3.根据指标值，进行2次排序
              const sortArr2 = this.sortSecond(sortArr1)
              // 4.赋值展示
              this.tableData = sortArr2.map((item) => {
                return {
                  storeName: item.storeName,
                  isMark: item.isMark,
                  formatsName: item.formatsName,
                  floorName: item.floorName,
                  indicator: item.rentEffect,
                  rankIndex: item.lcRentEffectRank,
                  storeCode: item.storeCode,
                  validFlag: item.validFlag
                }
              })
              this.showContrast()
            } else {
              this.showRankData = false
            }
          } else if (this.indicatorB === '同业态' && this.indicatorC === '全部楼层') {
            if (this.currentRankData) {
              this.showRankData = true
              // 一、数值处理
              // 1.获取当前排名
              this.rankNumber = this.currentRankData.ytRentEffectRank
              // 2.获取对比上个周期
              if (this.LastRankData) {
                const obj = this.compare(this.currentRankData.ytRentEffectRank, this.LastRankData.ytRentEffectRank) // 用现在的排名减过去的排名
                this.isChangeRank = obj.type
                this.rankNumberLast = obj.value
              }
              // 3.获取对比上年年周期
              if (this.lastYearRank) {
                const obj = this.compare(this.currentRankData.ytRentEffectRank, this.lastYearRank.ytRentEffectRank)
                this.isChangeRankYear = obj.type
                this.rankNumberLastYear = obj.value
              }
              // 二、列表排序处理, 排序方法以后优化
              // 1.筛选
              const filterArr = this.tableAllData.filter(item => {
                return item.formats1Code === this.currentRankData.formats1Code
              })
              // 2.根据排名数，进行1次排序
              const sortArr1 = filterArr.sort(this.sortytRentEffectRank)
              // 3.根据指标值，进行2次排序
              const sortArr2 = this.sortSecond(sortArr1)
              // 4.赋值展示
              this.tableData = sortArr2.map((item) => {
                return {
                  storeName: item.storeName,
                  isMark: item.isMark,
                  formatsName: item.formatsName,
                  floorName: item.floorName,
                  indicator: item.rentEffect,
                  rankIndex: item.ytRentEffectRank,
                  storeCode: item.storeCode,
                  validFlag: item.validFlag
                }
              })
              this.showContrast()
            } else {
              this.showRankData = false
            }
          } else if (this.indicatorB === '全部业态' && this.indicatorC === '全部楼层') {
            if (this.currentRankData) {
              this.showRankData = true
              // 一、数值处理
              // 1.获取当前排名
              this.rankNumber = this.currentRankData.rentEffectRank
              // 2.获取对比上个周期
              if (this.LastRankData) {
                const obj = this.compare(this.currentRankData.rentEffectRank, this.LastRankData.rentEffectRank) // 用现在的排名减过去的排名
                this.isChangeRank = obj.type
                this.rankNumberLast = obj.value
              }
              // 3.获取对比上年年周期
              if (this.lastYearRank) {
                const obj = this.compare(this.currentRankData.rentEffectRank, this.lastYearRank.rentEffectRank)
                this.isChangeRankYear = obj.type
                this.rankNumberLastYear = obj.value
              }
              // 二、列表排序处理, 排序方法以后优化
              // 1.筛选
              const filterArr = this.tableAllData.filter(item => {
                return item
              })
              // 2.根据排名数，进行1次排序
              const sortArr1 = filterArr.sort(this.sortrentEffectRank)
              // 3.根据指标值，进行2次排序
              const sortArr2 = this.sortSecond(sortArr1)
              // 4.赋值展示
              this.tableData = sortArr2.map((item) => {
                return {
                  storeName: item.storeName,
                  isMark: item.isMark,
                  formatsName: item.formatsName,
                  floorName: item.floorName,
                  indicator: item.rentEffect,
                  rankIndex: item.rentEffectRank,
                  storeCode: item.storeCode,
                  validFlag: item.validFlag
                }
              })
              this.showContrast()
            } else {
              this.showRankData = false
            }
          }
          // 二、指标值处理
          if (this.currentRankData) {
            this.showIndicatorData = true
            // 1.获取当前指标值
            this.indicatorData = this.currentRankData.rentEffect
            console.log('this.indicatorData', this.indicatorData)
            console.log('this.currentRankData.rentEffect', this.currentRankData.rentEffect)
            // 2.获取对比第一名
            if (this.storeName === this.tableData[0].storeName) { // 如果第一名是本店铺，就跟第二名比
              if (this.tableData[1]) {
                let percent = ''
                if (this.currentRankData.rentEffect) {
                  percent = (((this.currentRankData.rentEffect - this.tableData[1].indicator) / this.currentRankData.rentEffect) * 100).toFixed(2)
                }
                const obj = this.compare(this.currentRankData.rentEffect, this.tableData[1].indicator)
                this.isChangeIndicator = obj.type
                if (percent) {
                  this.indicatorDataLast = (this.tableData[1].indicator).toFixed(2) + '元/月/㎡' + ' ' + percent + '%'
                } else {
                  this.indicatorDataLast = ''
                }
                this.numberOneStoreName = JSON.parse(JSON.stringify(this.tableData[1].storeName)) // 获取排名第二的店铺名
              }
              this.reputation = '第二'
            } else { // 如果第一名不是本店铺，就跟第一名比
              let percent = ''
              if (this.tableData[0].indicator) {
                percent = (((this.tableData[0].indicator - this.currentRankData.rentEffect) / this.tableData[0].indicator) * 100).toFixed(2)
              }
              const obj = this.compare(this.tableData[0].indicator, this.currentRankData.rentEffect)
              this.isChangeIndicator = obj.type
              if (percent) {
                this.indicatorDataLast = (this.tableData[0].indicator).toFixed(2) + '元/月/㎡' + ' ' + percent + '%'
              } else {
                this.indicatorDataLast = ''
              }
              // 获取排名第一的店铺名
              this.reputation = '第一'
              this.numberOneStoreName = JSON.parse(JSON.stringify(this.tableData[0].storeName))
            }
            // 3.对比平均值
            let sum = 0
            for (let i = 0; i < this.tableData.length; i++) {
              sum = sum + this.tableData[i].indicator
            }
            const average = sum / this.tableData.length
            const object = this.compare(this.currentRankData.rentEffect, average)
            this.isChangeIndicatorYear = object.type
            if (average) {
              const percent = ((Math.abs(this.currentRankData.rentEffect - average) / average) * 100).toFixed(2)
              if (percent !== '0.00') {
                this.indicatorDataLastYear = average.toFixed(2) + '元/月/㎡' + ' ' + percent + '%'
              } else {
                this.indicatorDataLastYear = ''
              }
            } else {
              this.indicatorDataLastYear = ''
            }
          } else {
            this.showIndicatorData = false
          }
          break
        case '租售比':
          // 一、排名数据处理
          if (this.indicatorB === '同业态' && this.indicatorC === '同楼层') {
            if (this.currentRankData) {
              this.showRankData = true
              // 一、数值处理
              // 1.获取当前排名
              this.rankNumber = this.currentRankData.ytlcSalesRentRatioRank
              // 2.获取对比上个周期
              if (this.LastRankData) {
                const obj = this.compare(this.currentRankData.ytlcSalesRentRatioRank, this.LastRankData.ytlcSalesRentRatioRank) // 用现在的排名减过去的排名
                this.isChangeRank = obj.type
                this.rankNumberLast = obj.value
              }
              // 3.获取对比上年年周期
              if (this.lastYearRank) {
                const obj = this.compare(this.currentRankData.ytlcSalesRentRatioRank, this.lastYearRank.ytlcSalesRentRatioRank)
                this.isChangeRankYear = obj.type
                this.rankNumberLastYear = obj.value
              }
              // 二、列表排序处理, 排序方法以后优化
              // 1.筛选
              const filterArr = this.tableAllData.filter(item => {
                return item.formats1Code === this.currentRankData.formats1Code && item.floorCode === this.currentRankData.floorCode
              })
              // 2.根据排名数，进行1次排序
              const sortArr1 = filterArr.sort(this.sortYtlcsalesRentRatioRank)
              // 3.根据指标值，进行2次排序
              const sortArr2 = this.sortSecond(sortArr1)
              // 4.赋值展示
              this.tableData = sortArr2.map((item) => {
                return {
                  storeName: item.storeName,
                  isMark: item.isMark,
                  formatsName: item.formatsName,
                  floorName: item.floorName,
                  indicator: item.salesRentRatio,
                  rankIndex: item.ytlcSalesRentRatioRank,
                  storeCode: item.storeCode,
                  validFlag: item.validFlag
                }
              })
              this.showContrast()
            } else {
              this.showRankData = false
            }
          } else if (this.indicatorB === '全部业态' && this.indicatorC === '同楼层') {
            if (this.currentRankData) {
              this.showRankData = true
              // 一、数值处理
              // 1.获取当前排名
              this.rankNumber = this.currentRankData.lcSalesRentRatioRank
              // 2.获取对比上个周期
              if (this.LastRankData) {
                const obj = this.compare(this.currentRankData.lcSalesRentRatioRank, this.LastRankData.lcSalesRentRatioRank) // 用现在的排名减过去的排名
                this.isChangeRank = obj.type
                this.rankNumberLast = obj.value
              }
              // 3.获取对比上年年周期
              if (this.lastYearRank) {
                const obj = this.compare(this.currentRankData.lcSalesRentRatioRank, this.lastYearRank.lcSalesRentRatioRank)
                this.isChangeRankYear = obj.type
                this.rankNumberLastYear = obj.value
              }
              // 二、列表排序处理, 排序方法以后优化
              // 1.筛选
              const filterArr = this.tableAllData.filter(item => {
                return item.floorCode === this.currentRankData.floorCode
              })
              // 2.根据排名数，进行1次排序
              const sortArr1 = filterArr.sort(this.sortLcsalesRentRatioRank)
              // 3.根据指标值，进行2次排序
              const sortArr2 = this.sortSecond(sortArr1)
              // 4.赋值展示
              this.tableData = sortArr2.map((item) => {
                return {
                  storeName: item.storeName,
                  isMark: item.isMark,
                  formatsName: item.formatsName,
                  floorName: item.floorName,
                  indicator: item.salesRentRatio,
                  rankIndex: item.lcSalesRentRatioRank,
                  storeCode: item.storeCode,
                  validFlag: item.validFlag
                }
              })
              this.showContrast()
            } else {
              this.showRankData = false
            }
          } else if (this.indicatorB === '同业态' && this.indicatorC === '全部楼层') {
            if (this.currentRankData) {
              this.showRankData = true
              // 一、数值处理
              // 1.获取当前排名
              this.rankNumber = this.currentRankData.ytSalesRentRatioRank
              // 2.获取对比上个周期
              if (this.LastRankData) {
                const obj = this.compare(this.currentRankData.ytSalesRentRatioRank, this.LastRankData.ytSalesRentRatioRank) // 用现在的排名减过去的排名
                this.isChangeRank = obj.type
                this.rankNumberLast = obj.value
              }
              // 3.获取对比上年年周期
              if (this.lastYearRank) {
                const obj = this.compare(this.currentRankData.ytSalesRentRatioRank, this.lastYearRank.ytSalesRentRatioRank)
                this.isChangeRankYear = obj.type
                this.rankNumberLastYear = obj.value
              }
              // 二、列表排序处理, 排序方法以后优化
              // 1.筛选
              const filterArr = this.tableAllData.filter(item => {
                return item.formats1Code === this.currentRankData.formats1Code
              })
              // 2.根据排名数，进行1次排序
              const sortArr1 = filterArr.sort(this.sortYtsalesRentRatioRank)
              // 3.根据指标值，进行2次排序
              const sortArr2 = this.sortSecond(sortArr1)
              // 4.赋值展示
              this.tableData = sortArr2.map((item) => {
                return {
                  storeName: item.storeName,
                  isMark: item.isMark,
                  formatsName: item.formatsName,
                  floorName: item.floorName,
                  indicator: item.salesRentRatio,
                  rankIndex: item.ytSalesRentRatioRank,
                  storeCode: item.storeCode,
                  validFlag: item.validFlag
                }
              })
              this.showContrast()
            } else {
              this.showRankData = false
            }
          } else if (this.indicatorB === '全部业态' && this.indicatorC === '全部楼层') {
            if (this.currentRankData) {
              this.showRankData = true
              // 一、数值处理
              // 1.获取当前排名
              this.rankNumber = this.currentRankData.salesRentRatioRank
              // 2.获取对比上个周期
              if (this.LastRankData) {
                const obj = this.compare(this.currentRankData.salesRentRatioRank, this.LastRankData.salesRentRatioRank) // 用现在的排名减过去的排名
                this.isChangeRank = obj.type
                this.rankNumberLast = obj.value
              }
              // 3.获取对比上年年周期
              if (this.lastYearRank) {
                const obj = this.compare(this.currentRankData.salesRentRatioRank, this.lastYearRank.salesRentRatioRank)
                this.isChangeRankYear = obj.type
                this.rankNumberLastYear = obj.value
              }
              // 二、列表排序处理, 排序方法以后优化
              // 1.筛选
              const filterArr = this.tableAllData.filter(item => {
                return item
              })
              // 2.根据排名数，进行1次排序
              const sortArr1 = filterArr.sort(this.sortsalesRentRatioRank)
              // 3.根据指标值，进行2次排序
              const sortArr2 = this.sortSecond(sortArr1)
              // 4.赋值展示
              this.tableData = sortArr2.map((item) => {
                return {
                  storeName: item.storeName,
                  isMark: item.isMark,
                  formatsName: item.formatsName,
                  floorName: item.floorName,
                  indicator: item.salesRentRatio,
                  rankIndex: item.salesRentRatioRank,
                  storeCode: item.storeCode,
                  validFlag: item.validFlag
                }
              })
              this.showContrast()
            } else {
              this.showRankData = false
            }
          }
          // 二、指标值处理
          if (this.currentRankData) {
            this.showIndicatorData = true
            const salesRentRatio = this.currentRankData.salesRentRatio // 字符串转数字类型
            // 1.获取当前指标值
            this.indicatorData = this.currentRankData.salesRentRatio * 100
            // 2.获取对比第一名
            if (this.storeName === this.tableData[0].storeName) { // 如果第一名是本店铺，就跟第二名比
              if (this.tableData[1]) {
                let percent = ''
                if (salesRentRatio) {
                  percent = (((salesRentRatio - this.tableData[1].indicator) / salesRentRatio) * 100).toFixed(2)
                }
                const obj = this.compare(salesRentRatio, this.tableData[1].indicator)
                this.isChangeIndicator = obj.type
                if (percent) {
                  this.indicatorDataLast = (this.tableData[1].indicator).toFixed(2) + '%' + ' ' + percent + '%'
                } else {
                  this.indicatorDataLast = ''
                }
                this.numberOneStoreName = JSON.parse(JSON.stringify(this.tableData[1].storeName)) // 获取排名第二的店铺名
              }
              this.reputation = '第二'
            } else { // 如果第一名不是本店铺，就跟第一名比
              let percent = ''
              if (this.tableData[0].indicator) {
                percent = (((this.tableData[0].indicator - salesRentRatio) / this.tableData[0].indicator) * 100).toFixed(2)
              }
              const obj = this.compare(this.tableData[0].indicator, salesRentRatio)
              this.isChangeIndicator = obj.type
              if (percent) {
                this.indicatorDataLast = (this.tableData[0].indicator).toFixed(2) + '%' + ' ' + percent + '%'
              } else {
                this.indicatorDataLast = ''
              }
              // 获取排名第一的店铺名
              this.reputation = '第一'
              this.numberOneStoreName = JSON.parse(JSON.stringify(this.tableData[0].storeName))
            }
            // 3.对比平均值
            let sum = 0
            for (let i = 0; i < this.tableData.length; i++) {
              sum = sum + this.tableData[i].indicator
            }
            const average = sum / this.tableData.length
            const object = this.compare(salesRentRatio, average)
            this.isChangeIndicatorYear = object.type
            if (average) {
              const percent = ((Math.abs(salesRentRatio - average) / average) * 100).toFixed(2)
              if (percent !== '0.00') {
                this.indicatorDataLastYear = average.toFixed(2) + '%' + ' ' + percent + '%'
              } else {
                this.indicatorDataLastYear = ''
              }
            } else {
              this.indicatorDataLastYear = ''
            }
          } else {
            this.showIndicatorData = false
          }
          break
      }
    },
    toBaseClick (item) {
      if (item.validFlag === 1) {
        this.$router.push({
          name: 'earlywarningAnalysis',
          query: Object.assign({ projectId: this.filtroProject, storeCode: item.storeCode }, { _: +new Date() })
        })
      }
    },
    // 排名和周期的绿色折线图，写死的，仅占位用，无数据或其他交互
    getChart () {
      this.charts1 = echarts.init(document.getElementById('charts1'))
      this.charts2 = echarts.init(document.getElementById('charts2'))
      this.charts1.setOption(this.chartsOptionLine())
      this.charts2.setOption(this.chartsOptionLine())
      const obj = {
        series: [
          {
            symbol: 'none',
            type: 'line',
            smooth: true,
            emphasis: {
              focus: 'series'
            },
            lineStyle: {
              color: '#7FBA7A'
            },
            areaStyle: {
              opacity: 1,
              color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [{
                offset: 0,
                color: 'rgba(127, 186, 122, .4)'
              }, {
                offset: 1,
                color: 'rgba(127, 186, 122, 0)'
              }])
            },
            data: [120, 230, 101, 434, 40, 430, 110]
          }
        ]
      }
      this.charts1.setOption(obj)
      this.charts2.setOption(obj)
      this.$nextTick(() => {
        window.addEventListener('resize', () => {
          this.charts1.resize()
          this.charts2.resize()
        })
      })
    },
    chartsOptionLine () {
      const option = {
        grid: {
          top: '0%',
          left: '0%',
          right: '0%',
          bottom: '0%',
          containLabel: false
        },
        legend: false,
        xAxis: {
          boundaryGap: false,
          axisLine: {
            show: false
          },
          type: 'category',
          axisTick: {
            show: false
          },
          splitLine: {
            show: false
          }
        },
        yAxis: [{
          axisLine: {
            show: false
          },
          type: 'value',
          axisTick: {
            show: false
          },
          splitLine: {
            show: false
          }
        }],
        series: []
      }
      return option
    }
  }
}
</script>

<style lang="stylus" scoped>
.view1
  padding-top 60px
  background #272930
  opacity 1
  border-radius 0 0 2px 2px
  .selectBtn-view
    margin-top 17px
    padding-left 24px
    line-height 33px
    display flex
    flex-flow wrap
    .type-item
      margin-top 15px
      display flex
      font-size 14px
      font-family PingFang SC
      color #B2B3BD
      .type-item-btn
        padding 0 3px
        height 33px
        border 1px solid #373A43
        opacity 1
        border-radius 2px
        span
          display inline-block
          width 77px
          height 27px
          line-height 27px
          font-size 14px
          font-family PingFang SC
          font-weight 500
          color rgba(255, 255, 255, 0.5)
          text-align center
          margin-right -1px
          cursor pointer
          &.active
            background #FFA134
            color #fff
            border-color #FFA134
        span:first-child
          border-radius 2px 0px 0px 2px
        span:last-child
          border-radius 0px 2px 2px 0px
      .type-item-select
        background-color #373A43
        .segmentation
          color #424752
        span
          color #B2B3BD
        & >>> .el-select
          width 112px
        & >>> .el-date-editor
          width 100px
          // width 120px
          // width 144px
          input
            text-align center
  .tableChart
    display flex
    justify-content space-between
    margin-top 65px
    .w-table
      margin-left 24px
      margin-right 50px
      width 65%
      height 520px
      overflow-y auto
      table
        width 100%
        thead
          tr
            height 32px
            color #B2B3BD
            font-size 14px
            cursor pointer
            th
              border-bottom 1px solid #373A43
              text-align left
              vertical-align top
              &:first-child
                padding-right 25px
                text-align center
        tbody
          tr
            height 50px
            color #808191
            font-size 14px
            &:hover td
              background-color #373A43
            &:hover .progress-bg
              background-color #242731
            td
              border none
              border-bottom 1px solid #373A43
              text-align left
              &:first-child
                padding-right 25px
                text-align center
                img
                  width 20px
              &:nth-child(5)
                color #fff
              &:nth-child(6)
                padding-right 9.36%
          .mark
            background-color #FFF2F2
            color #262B30 !important
            &:hover td
              background-color #FFF2F2
            &:hover .progress-bg
              background-color #242731
    .chartView
      margin-right 24px
      width 35%
      .rank
        border 1px solid #373A43
        opacity 1
        border-radius 2px
        font-size 16px
        font-weight 400
        color #808191
      .rent
        margin-top 24px
        margin-bottom 24px
        border 1px solid #373A43
        opacity 1
        border-radius 2px
        font-size 16px
        font-weight 400
        color #808191
      .title
        margin 24px auto 38px 12px
        span
          vertical-align middle
          font-size 16px
          font-family PingFang SC
          font-weight 400
          color #FFF
          &:nth-child(3)
            color #808191
        img
          vertical-align middle
          margin-right 4px
          width 16px
          height 16px
      .chart
        margin-bottom 50px
        height 60px
        line-height 60px
        display flex
        justify-content space-between
        .number
          margin-left 32px
          font-size 60px
          font-family Bahnschrift
          font-weight bold
          color #FFFFFF
          opacity 1
        .chart-box
          margin 12px 24px auto 10px
          width 40%
          height 48px
      .comparison
        font-size 14px
        margin auto auto 12px 32px
        span
          font-size 16px
          color #fff
        .icon-data-down:before
          font-size 12px
          color #89DF81
        .icon-data-up:before
          font-size 12px
          color #FF754C
      .no-rank-data
        text-align center
        p
          margin-bottom 45px
          font-size 14px
          font-family PingFang SC
          font-weight 400
          line-height 41px
          color #808191
          opacity 1
.mr-70
  margin-right 70px
// elm-ui样式
// 1.下拉框
/deep/ .el-input__inner
  height 33px
  background-color #373A43
  color #808191
  border none
  border-radius 2px
  padding 0 18px
// 2.日期组件样式
// （1）日期icon
/deep/ .el-input__prefix
  display none
/deep/ .el-date-editor .el-input__suffix
  display none
// （2）输入框禁止点击时
/deep/ .is-disabled .el-input__inner
  color #808191 !important
  background-color #373a43 !important
</style>
