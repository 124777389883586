<template>
  <div>
    <div class="view1">
      <div class="selectBtn-view">
        <div class="type-item mr-70">
          左轴指标：
          <div class="type-item-btn">
            <span
              v-for="(item,index) in tabsListLeft"
              :key="index"
              @click="setIndicator(0, index)"
              :class="[tabsLeftValue === index ? 'active': '']"
            >{{item}}</span>
          </div>
        </div>
        <div class="type-item mr-70">
          右轴指标：
          <div class="type-item-btn">
            <span
              v-for="(item,index) in tabsListRight"
              :key="index"
              @click="setIndicator(1, index)"
              :class="[tabsRightValue === index ? 'active': '']"
            >{{item}}</span>
          </div>
        </div>
        <div class="type-item">
          时间范围：
          <div class="type-item-select">
            <el-select
              popper-class="w-block-select-down yellow-select-panel"
              v-model="timeType"
              size="small"
              placeholder="请选择"
              @change="handleData"
            >
              <el-option
                v-for="item in timeOptions"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              >
              </el-option>
            </el-select>
            <span class="segmentation">|</span>
            <el-date-picker
              popper-class="orange-el-date-picker"
              :disabled="noEdit"
              v-model="startTime"
              type="month"
              placeholder="选择日期"
              value-format="yyyy-MM"
              :picker-options="pickerOptions_start"
              @change="setStartTime()"
            >
            </el-date-picker>
            <span :class="noEdit?'isDisabled':''">~</span>
            <el-date-picker
              popper-class="orange-el-date-picker"
              :disabled="noEdit"
              v-model="endTime"
              type="month"
              placeholder="选择日期"
              value-format="yyyy-MM"
              :picker-options="pickerOptions_end"
              @change="setEndTime()"
            >
            </el-date-picker>
          </div>
        </div>
      </div>
      <div class="analysis">
        <div class="analysis-data">
          <h3 class="w-title-gradients">租金关联分析</h3>
          <ul class="info-ul">
            <li>
              <p v-if="tabsLeftValue === 0">累计租金(万元)</p>
              <p v-if="tabsLeftValue === 1">月均租金坪效(元/月/㎡)</p>
              <p v-if="tabsLeftValue === 2">租售比(%)</p>
              <span class="num-span">{{information.data1?information.data1:'-'}}</span>
            </li>
            <li>
              <p v-if="tabsRightValue === 0">累计销售额(万元)</p>
              <p v-if="tabsRightValue === 1">日均客流量(千人次/日)</p>
              <span class="num-span">{{information.data4 ?information.data4 :'-'}}</span>
            </li>
            <li>
              <p v-if="tabsLeftValue === 0">同楼层店铺月均租金(万元/月)</p>
              <p v-if="tabsLeftValue === 1">同楼层店铺月均租金坪效(元/月/㎡)</p>
              <p v-if="tabsLeftValue === 2">同楼层店铺租售比(%)</p>
              <span class="num-span">{{information.data3 ?information.data3 :'-'}}</span>
            </li>
            <li>
              <p v-if="tabsLeftValue === 0">月均租金(万元/月)</p>
              <p v-if="tabsLeftValue === 1">项目月均租金坪效(元/月/㎡)</p>
              <p v-if="tabsLeftValue === 2">项目租售比(%)</p>
              <span class="num-span">{{information.data2 ?information.data2 :'-'}}</span>
            </li>
            <li>
              <p v-if="tabsLeftValue === 0">同业态店铺月均租金(万元/月)</p>
              <p v-if="tabsLeftValue === 1">同业态店铺月均租金坪效(元/月/㎡)</p>
              <p v-if="tabsLeftValue === 2">同业态店铺租售比(%)</p>
              <span class="num-span">{{information.data5 ?information.data5 :'-'}}</span>
            </li>
          </ul>
        </div>
        <div class="analysis-chart">
          <div class="checkBox">
            <el-checkbox
              @change="showDatum"
              v-model="information.isShowDatumLine1"
            >{{information.datumLine1}}</el-checkbox>
            <el-checkbox
              @change="showDatum"
              v-model="information.isShowDatumLine2"
            >{{information.datumLine2}}</el-checkbox>
          </div>
          <div
            id="charts"
            class="charts-box"
          ></div>
        </div>
      </div>
    </div>
    <div class="view2">
      <h3 class="w-title-gradients">明细列表</h3>
      <span
        class="exportOut fr"
        @click="exportOutFunc()"
      >导出数据</span>
      <div class="clear"></div>
      <div
        class="w-table"
        :class="showTableData?'w-singed-table':'grey-singed-table'"
      >
        <table>
          <thead>
            <tr>
              <th width="5.96%">序号</th>
              <th width="12.90%">时间</th>
              <th width="15.69%">租金（元）</th>
              <th width="16.07%">租金坪效(元/月/㎡)</th>
              <th width="16.81%">租售比(%)</th>
              <th width="15.63%">销售额(元）</th>
              <th>客流量(人次)</th>
            </tr>
          </thead>
          <tbody>
            <tr
              v-for="(item, index) in tableData"
              :key="index"
            >
              <td>{{index + 1}}</td>
              <td>{{item.date}}</td>
              <td>{{item.rentMoney}}</td>
              <td>{{item.rentEffect}}</td>
              <td>{{item.salesRentRatio}}</td>
              <td>{{item.salesAmount}}</td>
              <td>{{item.passengerFlowNum}}</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>

<script>
import api from '@/api'
import echarts from 'echarts'
import { dateFormat } from '@/utils/index'
export default {
  name: 'salesLink',
  data () {
    return {
      tabsLeftValue: 0,
      tabsRightValue: 0,
      tabsListLeft: ['租金', '租金坪效', '租售比'],
      tabsListRight: ['销售额', '客流'],
      tableData: [],
      showTableData: true,
      chartsData: [],
      information: { // 关联分析中展示的数据
        data1: '',
        data2: '',
        data3: '',
        data4: '',
        data5: '',
        datumLine1: '同楼层店铺月均租金', // 基准线名称
        datumLine2: '同业态店铺月均租金', // 基准线名称
        isShowDatumLine1: false, // 是否显示基准线
        isShowDatumLine2: false // 是否显示基准线
      },
      infoList: {},
      timeType: '近一年',
      timeOptions: [{
        value: '近一年',
        label: '近一年'
      }, {
        value: '本年度',
        label: '本年度'
      }, {
        value: '自定义',
        label: '自定义'
      }],
      startTime: '',
      endTime: '',
      pickerOptions_start: {
        disabledDate: (time) => {
          if (this.endTime) {
            return time.getTime() > Date.now() || time.getTime() > this.endTime
          } else {
            return time.getTime() > Date.now()
          }
        }
      },
      pickerOptions_end: {
        disabledDate: (time) => {
          const t = new Date().getDate()
          return time.getTime() <= this.startTime || time.getTime() >= (Date.now() - 8.64e7 * t)
        }
      },
      charts: {},
      noEdit: false,
      isShowChart: true
    }
  },
  props: {
    filtroProject: {
      type: Number
    },
    filtroStore: {
      type: String
    }
  },
  watch: {
    filtroStore (newValue, oldValue) {
      this.getData()
    }
  },
  mounted () {
    this.setDefaultDate()
    this.setCurrentTime()
    this.getData()
  },
  methods: {
    // 生成时间范围数组
    getMonthBetween (start, end) {
      const result = []
      const s = start.split('-')
      const e = end.split('-')
      const min = new Date()
      const max = new Date()
      min.setFullYear(s[0], s[1])
      max.setFullYear(e[0], e[1])
      let curr = min
      while (curr <= max) {
        const month = curr.getMonth()
        let tempmonth = month + ''
        if (tempmonth.length === 1 && tempmonth !== '0') {
          tempmonth = '0' + tempmonth
        }
        let str = curr.getFullYear() + '-' + (tempmonth)
        const s = curr.getFullYear() + '-0'
        if (str === s) {
          str = curr.getFullYear() - 1 + '-12'
        }
        result.push(str)
        curr.setMonth(month + 1)
        curr = new Date(curr)
      }
      return result
    },
    setDefaultChart () {
      this.chartsData = []
      this.infoList = {
        avgPassengerFlowNum: '', // 日均客流数
        avgRentEffect: '', // 月均租金坪效
        avgRentMoney: '', // 月均租金额
        lcAvgRentEffect: '', // 同楼层月均租金坪效
        lcAvgRentMoney: '', // 同楼层月均租金额
        lcSalesRentRatio: '', // 同楼层租售比
        mallAvgRentEffect: '', // 项目月均租金坪效
        mallSalesRentRatio: '', // 项目租售比
        salesRentRatio: '', // 租售比
        totalRentMoney: '', // 累计租金额
        totalSalesAmount: '', // 累计销售额
        ytAvgRentEffect: '', // 同业态月均租金坪效
        ytAvgRentMoney: '', // 同业态月均租金额
        ytSalesRentRatio: '' // 同业态租售比
      }
      const Arr = this.getMonthBetween(this.startTime, this.endTime)
      for (let i = 0; i < Arr.length; i++) {
        const obj = {
          measureMonth: Arr[i],
          rentMoney: '',
          passengerFlowNum: '',
          salesAmount: '',
          rentEffect: '',
          salesRentRatio: ''
        }
        this.chartsData.push(obj)
      }
    },
    // 设置时间初始值
    setCurrentTime () {
      const end = new Date()
      const start = new Date()
      end.setTime(end.getTime() - 3600 * 1000 * 24 * 30)
      this.endTime = dateFormat('yyyy-MM', end)
      start.setTime(start.getTime() - 3600 * 1000 * 24 * 365)
      this.startTime = dateFormat('yyyy-MM', start)
      this.noEdit = true
    },
    // 省缺时显示的数据
    setDefaultDate () {
      this.tableData = []
      this.showTableData = false
      for (let i = 0; i < 8; i++) {
        const obj = {
          date: '-',
          salesAmount: '-',
          rentMoney: '-',
          rentEffect: '-',
          salesEffect: '-',
          salesRentRatio: '-',
          passengerFlowNum: '-'
        }
        this.tableData.push(obj)
      }
    },
    // 获取数据
    getData () {
      const params = {
        projectId: this.filtroProject,
        storeCode: this.filtroStore,
        rentStartMonth: this.startTime,
        rentEndMonth: this.endTime
      }
      this.axios.get(api.getStoreRentRelateAnalysis, { params: params }).then(res => {
        if (res.data.code === 0) {
          this.isShowChart = true
          const resData = res.data.data
          // 一、图表
          this.chartsData = resData.storeMonthRentVoList
          // 二、关联分析
          const relateDate = resData.storeRelateDataVo
          this.infoList = {
            avgPassengerFlowNum: '', // 日均客流数
            avgRentEffect: '', // 月均租金坪效
            avgRentMoney: '', // 月均租金额
            lcAvgRentEffect: '', // 同楼层月均租金坪效
            lcAvgRentMoney: '', // 同楼层月均租金额
            lcSalesRentRatio: '', // 同楼层租售比
            mallAvgRentEffect: '', // 项目月均租金坪效
            mallSalesRentRatio: '', // 项目租售比
            salesRentRatio: '', // 租售比
            totalRentMoney: '', // 累计租金额
            totalSalesAmount: '', // 累计销售额
            ytAvgRentEffect: '', // 同业态月均租金坪效
            ytAvgRentMoney: '', // 同业态月均租金额
            ytSalesRentRatio: '' // 同业态租售比
          }
          if (relateDate) {
            // 租金
            // 累计租金
            if (relateDate.totalRentMoney !== '' && relateDate.totalRentMoney !== undefined && relateDate.totalRentMoney !== null) {
              this.infoList.totalRentMoney = (relateDate.totalRentMoney / 10000).toFixed(2) // 单位转换：元转万元
            } else {
              this.infoList.totalRentMoney = ''
            }
            // 月均租金
            if (relateDate.avgRentMoney !== '' && relateDate.avgRentMoney !== undefined && relateDate.avgRentMoney !== null) {
              this.infoList.avgRentMoney = (relateDate.avgRentMoney / 10000).toFixed(2) // 单位转换：元转万元
            } else {
              this.infoList.avgRentMoney = ''
            }
            // 同楼层月均租金
            if (relateDate.lcAvgRentMoney !== '' && relateDate.lcAvgRentMoney !== undefined && relateDate.lcAvgRentMoney !== null) {
              this.infoList.lcAvgRentMoney = (relateDate.lcAvgRentMoney / 10000).toFixed(2) // 单位转换：元转万元
            } else {
              this.infoList.lcAvgRentMoney = ''
            }
            // 同业态月均租金
            if (relateDate.ytAvgRentMoney !== '' && relateDate.ytAvgRentMoney !== undefined && relateDate.ytAvgRentMoney !== null) {
              this.infoList.ytAvgRentMoney = (relateDate.ytAvgRentMoney / 10000).toFixed(2) // 单位转换：元转万元
            } else {
              this.infoList.ytAvgRentMoney = ''
            }
            // 租金坪效
            // 项目月均租金坪效
            if (relateDate.mallAvgRentEffect !== '' && relateDate.mallAvgRentEffect !== undefined && relateDate.mallAvgRentEffect !== null) {
              this.infoList.mallAvgRentEffect = relateDate.mallAvgRentEffect.toFixed(2)
            } else {
              this.infoList.mallAvgRentEffect = ''
            }
            // 月均租金坪效
            if (relateDate.avgRentEffect !== '' && relateDate.avgRentEffect !== undefined && relateDate.avgRentEffect !== null) {
              this.infoList.avgRentEffect = relateDate.avgRentEffect.toFixed(2)
            } else {
              this.infoList.avgRentEffect = ''
            }
            // 同楼层月均租金坪效
            if (relateDate.lcAvgRentEffect !== '' && relateDate.lcAvgRentEffect !== undefined && relateDate.lcAvgRentEffect !== null) {
              this.infoList.lcAvgRentEffect = relateDate.lcAvgRentEffect.toFixed(2)
            } else {
              this.infoList.lcAvgRentEffect = ''
            }
            // 同业态月均租金坪效
            if (relateDate.ytAvgRentEffect !== '' && relateDate.ytAvgRentEffect !== undefined && relateDate.ytAvgRentEffect !== null) {
              this.infoList.ytAvgRentEffect = relateDate.ytAvgRentEffect.toFixed(2)
            } else {
              this.infoList.ytAvgRentEffect = ''
            }
            // 租售比
            // 租售比
            if (relateDate.salesRentRatio !== '' && relateDate.salesRentRatio !== undefined && relateDate.salesRentRatio !== null) {
              this.infoList.salesRentRatio = relateDate.salesRentRatio
            } else {
              this.infoList.salesRentRatio = ''
            }
            // 项目租售比
            if (relateDate.mallSalesRentRatio !== '' && relateDate.mallSalesRentRatio !== undefined && relateDate.mallSalesRentRatio !== null) {
              this.infoList.mallSalesRentRatio = relateDate.mallSalesRentRatio
            } else {
              this.infoList.mallSalesRentRatio = ''
            }
            // 同楼层租售比
            if (relateDate.lcSalesRentRatio !== '' && relateDate.lcSalesRentRatio !== undefined && relateDate.lcSalesRentRatio !== null) {
              this.infoList.lcSalesRentRatio = relateDate.lcSalesRentRatio
            } else {
              this.infoList.lcSalesRentRatio = ''
            }
            // 同业态租售比
            if (relateDate.ytSalesRentRatio !== '' && relateDate.ytSalesRentRatio !== undefined && relateDate.ytSalesRentRatio !== null) {
              this.infoList.ytSalesRentRatio = relateDate.ytSalesRentRatio
            } else {
              this.infoList.ytSalesRentRatio = ''
            }
            // 累计销售额
            if (relateDate.totalSalesAmount !== '' && relateDate.totalSalesAmount !== undefined && relateDate.totalSalesAmount !== null) {
              this.infoList.totalSalesAmount = (relateDate.totalSalesAmount / 10000).toFixed(2) // 单位转化，元转万元
            } else {
              this.infoList.totalSalesAmount = ''
            }
            // 日均客流量
            if (relateDate.avgPassengerFlowNum !== '' && relateDate.avgPassengerFlowNum !== undefined && relateDate.avgPassengerFlowNum !== null) {
              this.infoList.avgPassengerFlowNum = (relateDate.avgPassengerFlowNum).toFixed(2)
            } else {
              this.infoList.avgPassengerFlowNum = ''
            }
          }
          // 三、明细列表
          this.tableData = [] // 重置数据
          this.showTableData = true
          this.tableData = resData.storeMonthRentVoList.map(item => {
            const obj = {
              date: item.measureMonth ? item.measureMonth : '-',
              salesAmount: item.salesAmount ? item.salesAmount : '-',
              rentMoney: item.rentMoney ? item.rentMoney : '-',
              rentEffect: item.rentEffect ? item.rentEffect : '-',
              salesRentRatio: item.salesRentRatio ? item.salesRentRatio : '-',
              passengerFlowNum: (item.passengerFlowNum === 0 || item.passengerFlowNum !== null) ? item.passengerFlowNum : '0'
            }
            return obj
          })
          this.showData()
        } else {
          this.isShowChart = false
          // 明细列表-省缺
          this.setDefaultDate()
          // 图表-省缺
          this.setDefaultChart()
          this.showData()
        }
      })
    },
    showData () {
      switch (this.tabsLeftValue) {
        case 0: // 租金
          // 关联数据展示
          this.information.data1 = this.infoList.totalRentMoney // 累计租金
          this.information.data2 = this.infoList.avgRentMoney // 月均租金
          this.information.data3 = this.infoList.lcAvgRentMoney // 同楼层月均租金
          this.information.data5 = this.infoList.ytAvgRentMoney // 同业态月均租金
          this.information.datumLine1 = '同楼层店铺月均租金'
          this.information.datumLine2 = '同业态店铺月均租金'
          // 图表数据处理
          if (this.chartsData.length > 0) {
            // 销售额
            if (this.tabsRightValue === 0) {
              const obj = {
                indicator: ['租金', '销售额'], // 左右轴
                unit: ['万元', '万元'], // 左右轴单位
                label: [], // 横坐标
                value1: [], // 第一条线的值
                value2: [], // 第二条线的值
                markLine1: this.infoList.lcAvgRentMoney, // 第一条线的基准线
                markLine2: this.infoList.ytAvgRentMoney // 第二条线的基准线
              }
              for (let i = 0; i < this.chartsData.length; i++) {
                obj.label.push(this.chartsData[i].measureMonth)
                obj.value1.push(this.chartsData[i].rentMoney ? (this.chartsData[i].rentMoney / 10000).toFixed(2) : '') // 单位换算
                obj.value2.push(this.chartsData[i].salesAmount ? (this.chartsData[i].salesAmount / 10000).toFixed(2) : '') // 单位换算
              }
              this.getChart(obj)
            } else if (this.tabsRightValue === 1) {
              const obj = {
                indicator: ['租金', '客流'], // 左右轴
                unit: ['万元', '千人次'], // 左右轴单位
                label: [], // 横坐标
                value1: [], // 第一条线的值
                value2: [], // 第二条线的值
                markLine1: this.infoList.lcAvgRentMoney, // 第一条线的基准线
                markLine2: this.infoList.ytAvgRentMoney // 第二条线的基准线
              }
              for (let i = 0; i < this.chartsData.length; i++) {
                obj.label.push(this.chartsData[i].measureMonth)
                obj.value1.push(this.chartsData[i].rentMoney ? (this.chartsData[i].rentMoney / 10000).toFixed(2) : '') // 单位换算
                obj.value2.push(this.chartsData[i].passengerFlowNum)
              }
              this.getChart(obj)
            }
          } else {
            this.isShowChart = false
            this.setDefaultChart()
          }
          break
        case 1: // 租金坪效
          // 关联数据展示
          this.information.data1 = this.infoList.avgRentEffect // 月均租金坪效
          this.information.data2 = this.infoList.mallAvgRentEffect // 项目月均租金坪效
          this.information.data3 = this.infoList.lcAvgRentEffect // 同楼层月均租金坪效
          this.information.data5 = this.infoList.ytAvgRentEffect // 同业态月均租金坪效
          this.information.datumLine1 = '同楼层店铺月均租金坪效'
          this.information.datumLine2 = '同业态店铺月均租金坪效'
          // 图表数据处理
          if (this.chartsData.length > 0) {
            // 销售额
            if (this.tabsRightValue === 0) {
              const obj = {
                indicator: ['租金坪效', '销售额'], // 左右轴
                unit: ['元/月/㎡', '万元'], // 左右轴单位
                label: [], // 横坐标
                value1: [], // 第一条线的值
                value2: [], // 第二条线的值
                markLine1: this.infoList.lcAvgRentEffect, // 第一条线的基准线
                markLine2: this.infoList.ytAvgRentEffect // 第二条线的基准线
              }
              for (let i = 0; i < this.chartsData.length; i++) {
                obj.label.push(this.chartsData[i].measureMonth)
                obj.value1.push(this.chartsData[i].rentEffect)
                obj.value2.push(this.chartsData[i].salesAmount ? (this.chartsData[i].salesAmount / 10000).toFixed(2) : '') // 单位换算
              }
              this.getChart(obj)
            } else if (this.tabsRightValue === 1) {
              const obj = {
                indicator: ['租金坪效', '客流'], // 左右轴
                unit: ['元/月/㎡', '千人次'], // 左右轴单位
                label: [], // 横坐标
                value1: [], // 第一条线的值
                value2: [], // 第二条线的值
                markLine1: this.infoList.lcAvgRentEffect, // 第一条线的基准线
                markLine2: this.infoList.ytAvgRentEffect // 第二条线的基准线
              }
              for (let i = 0; i < this.chartsData.length; i++) {
                obj.label.push(this.chartsData[i].measureMonth)
                obj.value1.push(this.chartsData[i].rentEffect)
                obj.value2.push(this.chartsData[i].passengerFlowNum)
              }
              this.getChart(obj)
            }
          } else {
            this.isShowChart = false
            this.setDefaultChart()
          }
          break
        case 2: // '租售比'
          // 关联数据展示
          this.information.data1 = this.infoList.salesRentRatio // 租售比
          this.information.data2 = this.infoList.mallSalesRentRatio // 项目租售比
          this.information.data3 = this.infoList.lcSalesRentRatio // 同楼层租售比
          this.information.data5 = this.infoList.ytSalesRentRatio // 同业态租售比
          this.information.datumLine1 = '同楼层店铺租售比'
          this.information.datumLine2 = '同业态店铺租售比'
          // 图表数据处理
          if (this.chartsData.length > 0) {
            // 销售额
            if (this.tabsRightValue === 0) {
              const obj = {
                indicator: ['租售比', '销售额'], // 左右轴
                unit: ['%', '万元'], // 左右轴单位
                label: [], // 横坐标
                value1: [], // 第一条线的值
                value2: [], // 第二条线的值
                markLine1: this.infoList.lcSalesRentRatio, // 第一条线的基准线
                markLine2: this.infoList.ytSalesRentRatio // 第二条线的基准线
              }
              for (let i = 0; i < this.chartsData.length; i++) {
                obj.label.push(this.chartsData[i].measureMonth)
                if (this.chartsData[i].salesRentRatio) {
                  obj.value1.push(Number(this.chartsData[i].salesRentRatio) * 100)
                } else {
                  obj.value1.push('')
                }
                obj.value2.push(this.chartsData[i].salesAmount ? (this.chartsData[i].salesAmount / 10000).toFixed(2) : '') // 单位换算
              }
              this.getChart(obj)
            } else if (this.tabsRightValue === 1) {
              const obj = {
                indicator: ['租售比', '客流'], // 左右轴
                unit: ['%', '千人次'], // 左右轴单位
                label: [], // 横坐标
                value1: [], // 第一条线的值
                value2: [], // 第二条线的值
                markLine1: this.infoList.lcSalesRentRatio, // 第一条线的基准线
                markLine2: this.infoList.ytSalesRentRatio // 第二条线的基准线
              }
              for (let i = 0; i < this.chartsData.length; i++) {
                obj.label.push(this.chartsData[i].measureMonth)
                if (this.chartsData[i].salesRentRatio) {
                  obj.value1.push(Number(this.chartsData[i].salesRentRatio) * 100)
                } else {
                  obj.value1.push('')
                }
                obj.value2.push(this.chartsData[i].passengerFlowNum)
              }
              this.getChart(obj)
            }
          } else {
            this.isShowChart = false
            this.setDefaultChart()
          }
          break
      }
      switch (this.tabsRightValue) {
        case '销售额':
          // 关联数据展示
          this.information.data4 = this.infoList.totalSalesAmount // 累计销售额
          break
        case '客流':
          // 关联数据展示
          this.information.data4 = this.infoList.avgPassengerFlowNum // 日均客流量
          break
      }
    },
    setIndicator (key, value) {
      if (key === 0) {
        this.tabsLeftValue = value
        this.information.isShowDatumLine1 = false
        this.information.isShowDatumLine2 = false
        this.showDatum()
      } else {
        this.tabsRightValue = value
      }
      this.showData()
    },
    handleData (val) {
      this.timeType = val
      const end = new Date()
      const start = new Date()
      end.setTime(end.getTime() - 3600 * 1000 * 24 * 30)
      this.endTime = dateFormat('yyyy-MM', end)
      // 重置基准线
      this.information.isShowDatumLine1 = false
      this.information.isShowDatumLine2 = false
      this.charts.setOption(this.setDatumLine('null', 'null'))
      switch (val) {
        case '近一年':
          start.setTime(start.getTime() - 3600 * 1000 * 24 * 365)
          this.startTime = dateFormat('yyyy-MM', start)
          this.noEdit = true
          this.getData()
          break
        case '本年度':
          start.setFullYear(start.getFullYear(), 0, 1)
          this.startTime = dateFormat('yyyy-MM', start)
          this.noEdit = true
          this.getData()
          break
        case '自定义':
          this.noEdit = false
          break
      }
    },
    setStartTime () {
      const start = new Date(this.startTime).getTime()
      const end = new Date(this.endTime).getTime()
      const difference = (end - start) / (1000 * 60 * 60 * 24)
      if (this.endTime) {
        if (start > end) {
          this.$message({
            type: 'warning',
            message: '开始时间不能大于结束时间'
          })
          return false
        }
        if (difference > 365 * 3) {
          this.$message({
            type: 'warning',
            message: '时间范围不能超过3年'
          })
          return false
        }
        this.getData()
      }
    },
    setEndTime () {
      const start = new Date(this.startTime).getTime()
      const end = new Date(this.endTime).getTime()
      const difference = (end - start) / (1000 * 60 * 60 * 24)
      if (this.startTime) {
        if (start > end) {
          this.$message({
            type: 'warning',
            message: '开始时间不能大于结束时间'
          })
          return false
        }
        if (difference > 365 * 3) {
          this.$message({
            type: 'warning',
            message: '时间范围不能超过3年'
          })
          return false
        }
        this.getData()
      }
    },
    // 图表
    getChart (obj) {
      // console.log('obj', obj)
      this.charts = echarts.init(document.getElementById('charts'))
      this.charts.setOption(this.chartsOptionLine(obj))
      this.charts.setOption({
        legend: {
          data: obj.indicator
        },
        xAxis: [
          {
            data: obj.label
          }
        ],
        series: [
          {
            name: obj.indicator[0],
            type: 'line',
            smooth: true,
            emphasis: {
              focus: 'series'
            },
            lineStyle: {
              color: '#3F8CFF'
            },
            areaStyle: {
              opacity: 1,
              color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [{
                offset: 0,
                color: 'rgba(62, 140, 255, .3)'
              }, {
                offset: 1,
                color: 'rgba(62, 140, 255, 0)'
              }])
            },
            data: obj.value1
          },
          {
            name: obj.indicator[1],
            type: 'line',
            smooth: true,
            yAxisIndex: 1,
            emphasis: {
              focus: 'series'
            },
            lineStyle: {
              color: 'rgba(160, 215, 231, 1)'
            },
            areaStyle: {
              opacity: 1,
              color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [{
                offset: 0,
                color: 'rgba(160, 215, 231, .3)'
              }, {
                offset: 1,
                color: 'rgba(160, 215, 231, 0)'
              }])
            },
            data: obj.value2
          }
        ]
      })
      this.$nextTick(() => {
        window.addEventListener('resize', () => {
          this.charts.resize()
        })
      })
    },
    chartsOptionLine (obj) {
      // console.log('objjj', obj)
      // 1.先比较两条基准线的最大值
      const line1Value = obj.markLine1 ? Number(obj.markLine1) : 0 // 第一条基准线的值
      const line2Value = obj.markLine2 ? Number(obj.markLine2) : 0 // 第二条基准线的值
      let littleMax = ''
      if (line1Value > line2Value) {
        littleMax = line1Value
      } else {
        littleMax = line2Value
      }
      // 2.比较基准线和Y轴的最大值
      const maxTemp = Math.max(...obj.value1) // 数据中的最大值
      let finalMax = ''
      if (littleMax > maxTemp) {
        finalMax = littleMax
      } else {
        finalMax = maxTemp
      }
      // 双y轴实现左右刻度线一致。设置坐标轴分割间隔。(最大值 - 最小值)/分割段数
      const maxValue1 = finalMax || 30 // 无数据时最大值默认设30
      const minValue1 = Math.min(...obj.value1) ? Math.min(...obj.value1) : 0
      const intervalValue1 = (maxValue1 - minValue1) / 5 ? (maxValue1 - minValue1) / 5 : null
      const maxValue2 = Math.max(...obj.value2) ? Math.max(...obj.value2) : 175 // 无数据时最大值默认设175
      const minValue2 = Math.min(...obj.value2) ? Math.min(...obj.value2) : 0
      const intervalValue2 = (maxValue2 - minValue2) / 5 ? (maxValue2 - minValue2) / 5 : null
      // 设置legend位置
      let rightValue = ''
      if (this.tabsLeftValue === 0) {
        rightValue = 370
      } else if (this.tabsLeftValue === 1) {
        rightValue = 425
      } else if (this.tabsLeftValue === 2) {
        rightValue = 343
      }
      const option = {
        color: ['#3F8CFF', '#A0D7E7'],
        legend: {
          itemHeight: 10,
          itemWidth: 10,
          itemGap: 30,
          top: 2,
          right: rightValue,
          icon: 'circle',
          textStyle: {
            color: 'rgba(255, 255, 255, .45)',
            fontFamily: 'PingFang SC',
            fontSize: 12
          }
        },
        grid: {
          top: '20%',
          left: '1%',
          right: 24,
          bottom: '5%',
          containLabel: true
        },
        // dataZoom: [{
        //   type: 'slider',
        //   height: '6',
        //   show: true,
        //   showDetail: false,
        //   xAxisIndex: [0],
        //   left: '1%',
        //   right: '1%',
        //   bottom: '2%',
        //   start: 0,
        //   borderRadius: 8,
        //   fillerColor: '#F89407',
        //   backgroundColor: '#424752',
        //   end: 80 // 初始化滚动条
        // }],
        tooltip: {
          trigger: 'axis',
          backgroundColor: 'rgba(0,0,0,0.85)',
          textStyle: {
            fontSize: 12
          },
          formatter: (params) => {
            let html = '<div style="padding:4px; font-family: PingFang SC;">' + '<p style="color:#b2b3bd; text-align:center;">' + params[0].name + '</p>'
            for (let i = 0; i < params.length; i++) {
              if (params[i].seriesName === '客流') {
                html += '<span style="color:#b2b3bd;">' + params[i].marker + params[i].seriesName + '： ' + '</span>' + ((params[i].data || params[i].data === 0) ? params[i].data : '0') + obj.unit[i] + '<br>'
              } else {
                html += '<span style="color:#b2b3bd;">' + params[i].marker + params[i].seriesName + '： ' + '</span>' + ((params[i].data || params[i].data === 0) ? params[i].data : '-') + obj.unit[i] + '<br>'
              }
            }
            html += '</div>'
            return html
          }
        },
        xAxis: {
          type: 'category',
          offset: 6,
          boundaryGap: false,
          axisTick: {
            show: false
          },
          axisLabel: {
            color: '#808191'
          },
          axisLine: {
            lineStyle: {
              color: '#373A43'
            }
          },
          axisPointer: {
            lineStyle: {
              color: 'rgba(255,255,255,.1)'
            }
          },
          data: []
        },
        yAxis: [{
          name: obj.indicator[0] + '(' + obj.unit[0] + ')',
          nameTextStyle: {
            color: '#808191',
            fontFamily: 'PingFang SC',
            fontSize: 12
          },
          nameRotate: '0.1',
          interval: intervalValue1,
          splitNumber: 5,
          min: minValue1,
          max: maxValue1,
          type: 'value',
          axisTick: {
            show: false
          },
          position: 'left',
          splitLine: {
            lineStyle: {
              color: '#373A43',
              type: 'dashed'
            }
          },
          axisLine: {
            lineStyle: {
              color: '#373A43'
            }
          },
          axisLabel: {
            color: '#808191',
            formatter: function (value) {
              return value.toFixed(2)
            }
          }
        }, {
          name: obj.indicator[1] + '(' + obj.unit[1] + ')',
          nameTextStyle: {
            color: '#808191',
            fontFamily: 'PingFang SC',
            padding: [0, 12, 0, 0],
            fontSize: 12
          },
          interval: intervalValue2,
          splitNumber: 5,
          min: minValue2,
          max: maxValue2,
          type: 'value',
          axisTick: {
            show: false
          },
          position: 'right',
          splitLine: {
            lineStyle: {
              color: '#373A43',
              type: 'dashed'
            }
          },
          axisLine: {
            show: false
          },
          axisLabel: {
            color: '#808191',
            formatter: '{value}'
          }
        }],
        series: []
      }
      return option
    },
    // 设置基准线
    setDatumLine (yaxis1, yaxis2) {
      let option = {}
      if (yaxis1 === 'null' && yaxis2 === 'null') {
        option = {
          series: [
            {
              markLine: null
            }
          ]
        }
      } else if (yaxis1 !== 'null' && yaxis2 === 'null') {
        option = {
          series: [
            {
              yAxisIndex: 0,
              markLine: {
                label: {
                  show: false
                },
                symbol: 'none',
                lineStyle: {
                  width: 2
                },
                data: [
                  {
                    yAxis: yaxis1,
                    lineStyle: { color: '#89DF81' }
                  }, {
                    yAxis: 0,
                    lineStyle: { color: 'rgba(129, 110, 255, 0)' } // 隐藏
                  }
                ]
              }
            }
          ]
        }
      } else if (yaxis1 === 'null' && yaxis2 !== 'null') {
        option = {
          series: [
            {
              yAxisIndex: 0,
              markLine: {
                label: {
                  show: false
                },
                symbol: 'none',
                lineStyle: {
                  width: 2
                },
                data: [
                  {
                    yAxis: 0,
                    lineStyle: { color: 'rgba(137, 223, 129, 0)' } // 隐藏
                  }, {
                    yAxis: yaxis2,
                    lineStyle: { color: '#816EFF' }
                  }
                ]
              }
            }
          ]
        }
      } else if (yaxis1 !== 'null' && yaxis2 !== 'null') {
        option = {
          series: [
            {
              yAxisIndex: 0,
              markLine: {
                label: {
                  show: false
                },
                symbol: 'none',
                lineStyle: {
                  width: 2
                },
                data: [
                  {
                    yAxis: yaxis1,
                    lineStyle: { color: '#89DF81' }
                  }, {
                    yAxis: yaxis2,
                    lineStyle: { color: '#816EFF' }
                  }
                ]
              }
            }
          ]
        }
      }
      return option
    },
    // 显示基准线
    showDatum () {
      const line1 = this.information.isShowDatumLine1
      const line2 = this.information.isShowDatumLine2
      const markLineLcSalesRentRatio = Number(this.infoList.lcSalesRentRatio.replace('%', '')) // 同楼层店铺租售比
      const markLineYtSalesRentRatio = Number(this.infoList.ytSalesRentRatio.replace('%', '')) // 同业态店铺租售比
      switch (this.tabsLeftValue) {
        case 0: // 租金
          // 同楼层店铺月均租金 this.infoList.lcAvgRentMoney
          // 同业态店铺月均租金 this.infoList.ytAvgRentMoney
          if (line1 && line2) {
            this.charts.setOption(this.setDatumLine(this.infoList.lcAvgRentMoney, this.infoList.ytAvgRentMoney))
          } else if (line1 && !line2) {
            this.charts.setOption(this.setDatumLine(this.infoList.lcAvgRentMoney, 'null'))
          } else if (!line1 && line2) {
            this.charts.setOption(this.setDatumLine('null', this.infoList.ytAvgRentMoney))
          } else if (!line1 && !line2) {
            this.charts.setOption(this.setDatumLine('null', 'null'))
          }
          break
        case 1: // 租金坪效
          // 同楼层店铺月均租金坪效 this.infoList.lcAvgRentEffect
          // 同业态店铺月均租金坪效 this.infoList.ytAvgRentEffect
          if (line1 && line2) {
            this.charts.setOption(this.setDatumLine(this.infoList.lcAvgRentEffect, this.infoList.ytAvgRentEffect))
          } else if (line1 && !line2) {
            this.charts.setOption(this.setDatumLine(this.infoList.lcAvgRentEffect, 'null'))
          } else if (!line1 && line2) {
            this.charts.setOption(this.setDatumLine('null', this.infoList.ytAvgRentEffect))
          } else if (!line1 && !line2) {
            this.charts.setOption(this.setDatumLine('null', 'null'))
          }
          break
        case 2: // 租售比
          if (line1 && line2) {
            this.charts.setOption(this.setDatumLine(markLineLcSalesRentRatio, markLineYtSalesRentRatio))
          } else if (line1 && !line2) {
            this.charts.setOption(this.setDatumLine(markLineLcSalesRentRatio, 'null'))
          } else if (!line1 && line2) {
            this.charts.setOption(this.setDatumLine('null', markLineYtSalesRentRatio))
          } else if (!line1 && !line2) {
            this.charts.setOption(this.setDatumLine('null', 'null'))
          }
          break
      }
    },
    exportOutFunc () {
      this.axios.post(api.exportRentData, { mallCode: this.filtroProject, storeCode: this.filtroStore, rentStartMonth: this.startTime, rentEndMonth: this.endTime }).then(res => {
        if (res.data.status.code === '10000') {
          this.downLoadFile('/apiexport/dp' + res.data.data)
        } else {
          this.$message({
            type: 'warning',
            message: res.data.status.msg
          })
        }
      })
    },
    downLoadFileAjax (url) {
      return this.axios({ // 用axios发送post请求
        method: 'get',
        url: url, // 请求地址
        responseType: 'blob', // 表明返回服务器返回的数据类型
        headers: {
          'Content-Type': 'application/json'
        }
      })
    },
    downLoadFile (url) {
      this.loading = true
      this.downLoadFileAjax(url).then((res) => { // 处理返回的文件流
        const blob = new Blob([res.data])
        const temp = url.split('/')
        const fileName = temp[temp.length - 1]
        // 兼容ie.xlsx
        if (window.navigator.msSaveOrOpenBlob) {
          window.navigator.msSaveBlob(blob, fileName)
        } else {
          const elink = document.createElement('a')
          elink.download = fileName
          elink.style.display = 'none'
          elink.href = URL.createObjectURL(blob)
          document.body.appendChild(elink)
          elink.click()
          setTimeout(() => {
            URL.revokeObjectURL(elink.href) // 释放URL 对象
            document.body.removeChild(elink)
          }, 1000)
        }
        this.loading = false
      })
    }
  }
}
</script>

<style lang="stylus" scoped>
.view1
  padding-top 60px
  background #272930
  opacity 1
  border-radius 0 0 2px 2px
  .selectBtn-view
    margin-top 17px
    padding-left 24px
    line-height 33px
    display flex
    flex-flow wrap
    .type-item
      margin-top 15px
      display flex
      font-size 14px
      font-family PingFang SC
      color #B2B3BD
      .type-item-btn
        padding 0 3px
        height 33px
        border 1px solid #373A43
        opacity 1
        border-radius 2px
        span
          display inline-block
          width 77px
          height 27px
          line-height 27px
          font-size 14px
          font-family PingFang SC
          font-weight 500
          color rgba(255, 255, 255, 0.5)
          text-align center
          margin-right -1px
          cursor pointer
          &.active
            background #FFA134
            color #fff
            border-color #FFA134
        span:first-child
          border-radius 2px 0px 0px 2px
        span:last-child
          border-radius 0px 2px 2px 0px
      .type-item-select
        background-color #373A43
        .segmentation
          color #424752
        .isDisabled
          color rgba(128, 129, 145, 0.5)
        span
          color #B2B3BD
        & >>> .el-select
          width 112px
        & >>> .el-date-editor
          width 100px
          // width 144px
          input
            text-align center
  .checkBox
    position absolute
    right 48px
    & label:first-child >>> .el-checkbox__inner
      border 1px #808191 solid !important
    & label:first-child >>> .el-checkbox__input.is-checked .el-checkbox__inner
      background-color #89DF81 !important
      border-color #89DF81 !important
    & label:first-child >>> .is-checked + span
      color #fff !important
    & label:first-child >>> .el-checkbox__input.is-checked
      border-color #89DF81 !important
    & label:nth-child(2) >>> .el-checkbox__inner
      border 1px #808191 solid !important
    & label:nth-child(2) >>> .el-checkbox__input.is-checked .el-checkbox__inner
      background-color #816EFF !important
      border-color #816EFF !important
    & label:nth-child(2) >>> .is-checked + span
      color #fff !important
    & label:nth-child(2) >>> .el-checkbox__input.is-checked
      border-color #816EFF !important
  .analysis
    display flex
    margin-top 63px
    height 420px
    .analysis-chart
      flex 1
      .charts-box
        width 100%
        height 420px
    .analysis-data
      flex 0 0 480px
      .info-ul
        li:nth-child(2), li:nth-child(4)
          width 231px
          padding-left 40px
        li
          padding-left 24px
          display inline-block
          width 245px
          box-sizing border-box
          text-align left
          margin-top 45px
          font-weight 500
          color #70747D
          p
            line-height 25px
            font-size 14px
      .num-span
        color #fff
        font-size 26px
        font-weight bold
.view2
  margin-top 14px
  padding-bottom 24px
  background #272930
  border-radius 2px
  .exportOut
    margin -4px 25px 30px 0
    font-size 16px
    font-weight 400
    color #B2B3BD
    cursor pointer
    &:before
      margin-right 6px
      font-family 'iconfont'
      content '\e60b'
    &:hover
      color #f89407
  .w-table
    margin 0 24px
    padding 0 !important
    height 442px
    overflow-y auto
    table
      width 100%
      thead
        tr
          height 54px
          color #B2B3BD
          font-size 14px
          th
            border-bottom 1px solid #373A43
            text-align left
      tbody
        tr
          height 49px
          color #808191
          font-size 14px
          &:hover td
            background-color #23252b
          td
            border none
            border-bottom 1px solid #373A43
            text-align left
.clear
  clear both
.mr-70
  margin-right 70px
// elm-ui样式
// 1.下拉框
/deep/ .el-input__inner
  height 33px
  background-color #373A43
  color #808191
  border none
  border-radius 2px
  padding 0 18px
// 2.日期组件样式
// （1）日期icon
/deep/ .el-input__prefix
  display none
/deep/ .el-date-editor .el-input__suffix
  display none
// （2）输入框禁止点击时
/deep/ .is-disabled .el-input__inner
  color rgba(128, 129, 145, 0.5) !important
  background-color #373a43 !important
</style>
